import { MessageResponseDto } from '@domain/models/MessageResponseDto';
import { UniqueHashId } from '@domain/types';
import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { Service } from 'typedi';

@Service()
export class FileManagerApi {
  constructor(private http: BackendHttpClient) {}

  async getSignedS3Url(fileName: string, abGroup: string, tempId: UniqueHashId): Promise<string> {
    return this.http.get('/intops/admin/s3/sign', { fileName, abGroup, tempId });
  }

  async uploadFileToAWSBucket(file: File, uploadUrl: string): Promise<MessageResponseDto> {
    return this.http.put(uploadUrl, file, { headers: { Authorization: undefined } });
  }
}
