import React from 'react';

import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { FormComponent } from '@ui/hooks/form';
import { RegionInput } from '@pages/experimentDetails/components/stats/components/result/components/filters/atoms/RegionInput';
import { ARPUInput } from '@pages/experimentDetails/components/stats/components/result/components/filters/atoms/ARPUInput';
import { VersionInput } from '@pages/experimentDetails/components/stats/components/result/components/filters/atoms/VersionInput';
import { DatePagination } from '@pages/experimentDetails/components/stats/components/result/components/filters/atoms/DatePagination';
import { RequestAnalysisBtn } from '@pages/experimentDetails/components/stats/components/result/components/filters/atoms/RequestAnalysisBtn';
import ToggleColumnsButton from '@pages/experimentDetails/components/stats/components/result/components/filters/components/toggleColumns/ToggleColumnsButton';
import { ReactComponent as InfoSvg } from '@assets/svg/info.svg';

import styles from '@pages/experimentDetails/components/stats/components/result/components/filters/Filters.module.scss';
import { Tooltip } from 'crazy-ui';

type Props = Pick<FormComponent<IterationRequestParams>, 'control'> & { disabled: boolean; isIAPExist: boolean };

export function Filters({ control, disabled, isIAPExist }: Props) {
  return (
    <div className={styles.filters}>
      <RegionInput control={control} disabled={disabled} />
      <ARPUInput control={control} disabled={disabled} />
      <VersionInput control={control} disabled={disabled} />
      <DatePagination />
      {isIAPExist && (
        <Tooltip text="The Bayesian model was not used since this game has a significant InApp ARPU.">
          <InfoSvg className={styles.tooltipIcon} />
        </Tooltip>
      )}
      <RequestAnalysisBtn />
      <ToggleColumnsButton />
    </div>
  );
}
