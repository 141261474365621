import React from 'react';
import { useController, Control, useWatch, UseFormSetValue, FieldValues, Path } from 'react-hook-form';
import { DropdownInput, DropdownOption } from 'crazy-ui-next';

import { UserProperty } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { UserPropertyInput } from '@domain/models/createExperiment/userProperties/UserPropertiesParams';

import styles from '@pages/createExperiment/targetConfig/userProperties/UserProperties.module.scss';

type Props<T extends FieldValues> = {
  inputIndex: number;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  userProperties: UserProperty[];
  watchName: Path<T>;
  isDisabled?: boolean;
};

export function UserPropertiesDropdownInput<T extends FieldValues>({
  inputIndex,
  control,
  userProperties,
  setValue,
  watchName,
  isDisabled
}: Props<T>) {
  const inputName = `${watchName}.${inputIndex}.userPropertyId`;
  // @ts-ignore-next-line
  const inputs: UserPropertyInput[] = useWatch({ control, name: watchName });
  const { field } = useController({
    // @ts-ignore-next-line
    name: inputName,
    control
  });

  // DropdownInput doesn't support disabled options
  // current solution is work around to filter out already chosen user properties
  const chosenUserPropertyIds = inputs
    .map((input: UserPropertyInput) => +input.userPropertyId)
    .filter((id) => id !== +field.value);

  const options: DropdownOption<string>[] = userProperties
    .filter(({ id }) => !chosenUserPropertyIds.includes(id))
    .map((userProperty) => ({
      value: String(userProperty.id),
      label: userProperty.displayName
    }));

  const handleChange = (event) => {
    const { value } = event;
    const userProperty = userProperties.find((property) => property.id === +value);
    const displayName = userProperty ? userProperty.displayName : '';

    // @ts-ignore-next-line
    setValue(`${watchName}.${inputIndex}.userPropertyId`, value);
    // @ts-ignore-next-line
    setValue(`${watchName}.${inputIndex}.userPropertyDisplayName`, displayName);
  };

  return (
    <DropdownInput
      options={options}
      name={inputName}
      value={field.value}
      handleChange={handleChange}
      className={styles.dropdown}
      disabled={isDisabled}
    />
  );
}
