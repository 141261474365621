import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';
import { useController } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';

import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';

import styles from '../ConfigInput.module.scss';

type Props = Pick<FormComponent<DevPhaseParams>, 'control'> & {
  configIndex: number;
  isDisabled: boolean;
};

export function ShutdownCheckbox({ configIndex, control, isDisabled }: Props) {
  const { field } = useController({ control, name: `objectiveConfig.config.${configIndex}.active` });
  const { value, onChange } = field;

  return (
    <td className={styles.checkboxCell}>
      <CheckboxInput
        name={`objectiveConfig.config.${configIndex}.active`}
        labelText=""
        checked={value}
        onChange={onChange}
        disabled={isDisabled}
      />
    </td>
  );
}
