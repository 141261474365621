import { formatNumber, formatPercent } from '@infrastructure/utils/libs/formatData';
import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';

export const MetricDataType = {
  INT: 'int',
  FLOAT: 'float',
  PERCENT: 'percent',
  BOOL: 'bool',
  TEXT: 'text'
};

export const DESCRIPTION = 'description';

const KPI_RATE = 'kpiRate';
const KPI_TOTAL = 'kpiTotal';
const GAIN_PERCENT = 'gainPercentage';
const LOSS_PERCENT = 'lossPercentage';
const PROB_TO_BE_BEST = 'probToBeBest';
const PROB_TO_BEAT_CONTROL = 'probToBeatControl';

const PROB_BETTER = 'probBetter';
const PROB_EQUAL = 'probEqual';
const PROB_WORSE = 'probWorse';

const RATE_HIGH = 'rateHigh';
const RATE_LOW = 'rateLow';
const USERS = 'users';
const EFFECT_SIZE_HDI = 'effectSizeHdi';
const REGION = 'region';
const ARPU = 'arpu';
const LIFT_PERCENTAGE = 'liftPercentage';
const T_STATISTIC = 'tstatistics';
const P_VALUE = 'pvalue';
const POWER_PERCENTAGE = 'powerPercentage';

const IMPR_HIGH_PERCENT = 'improvementHighPercentage';
const IMPR_LOW_PERCENT = 'improvementLowPercentage';
const IMPR_PERCENT = 'improvementPercentage';

const INTERS_PER_USER = 'intersPerUsers';
const INTERS_TOTAL = 'intersTotal';
const INTERS_REV_PER_USER = 'intersRevenuePerUser';
const INTERS_REV_TOTAL = 'intersRevenueTotal';
const ILDR_ECPM = 'ilrdEcpm';
const INTERS_PER_MINUTE = 'intersPerMinute';

const RVS_PER_USER = 'rvsPerUser';
const RVS_TOTAL = 'rvsTotal';
const RVS_REV_PER_USER = 'rvsRevenuePerUser';
const RVS_REV_TOTAL = 'rvsRevenueTotal';
const RVS_ECPM = 'rvsEcpm';

const BANNERS_PER_USER = 'bannersPerUser';
const BANNERS_TOTAL = 'bannersTotal';
const BANNERS_REV_PER_USER = 'bannersRevenuePerUser';
const BANNERS_REV_TOTAL = 'bannersRevenueTotal';
const BANNERS_ECPM = 'bannersEcpm';

const APP_OPEN_PER_USER = 'appOpenPerUser';
const APP_OPEN_TOTAL = 'appOpenTotal';
const APP_OPEN_REV_PER_USER = 'appOpenRevenuePerUser';
const APP_OPEN_REV_TOTAL = 'appOpenRevenueTotal';
const APP_OPEN_ECPM = 'appOpenEcpm';

const ADS_REVENUE_PER_USER = 'adsRevenuePerUser';
const ADS_REVENUE_TOTAL = 'adsRevenueTotal';
const IAP_REVENUE_PER_USER = 'iapRevenuePerUser';
const IAP_REVENUE_TOTAL = 'iapRevenueTotal';

const REWARDED_INTERS_PER_USER = 'rewardedIntersPerUser';
const REWARDED_INTERS_TOTAL = 'rewardedIntersTotal';
const REWARDED_INTERS_REV_PER_USER = 'rewardedIntersRevenuePerUser';
const REWARDED_INTERS_REV_TOTAL = 'rewardedIntersRevenueTotal';
const REWARDED_INTERS_ECPM = 'rewardedIntersEcpm';

const USAGE_PER_USER = 'usagePerUser';
const USAGE_TOTAL = 'usageTotal';
const NUMBER_OF_COHORTS = 'numberOfCohorts';

const LOSS = 'loss';
const GAIN = 'gain';

export const experimentVariablesTypes = {
  INTEGER: MetricDataType.INT,
  INT_LIST: MetricDataType.INT,
  BOOLEAN: MetricDataType.BOOL
};

export const aBOldMetricKeyOrder = [
  REGION,
  ARPU,
  USERS,
  KPI_RATE,
  T_STATISTIC,
  P_VALUE,
  POWER_PERCENTAGE,
  LIFT_PERCENTAGE
];

export const metricKeyOrder = [
  USERS,
  NUMBER_OF_COHORTS,
  KPI_RATE,

  GAIN_PERCENT,
  LOSS_PERCENT,
  PROB_TO_BEAT_CONTROL,
  PROB_TO_BE_BEST,
  PROB_BETTER,
  PROB_EQUAL,
  PROB_WORSE,
  KPI_TOTAL,

  INTERS_TOTAL,
  INTERS_REV_TOTAL,
  INTERS_REV_PER_USER,
  INTERS_PER_USER,
  INTERS_PER_MINUTE,
  ILDR_ECPM,

  RVS_TOTAL,
  RVS_REV_TOTAL,
  RVS_REV_PER_USER,
  RVS_PER_USER,
  RVS_ECPM,

  BANNERS_TOTAL,
  BANNERS_REV_TOTAL,
  BANNERS_REV_PER_USER,
  BANNERS_PER_USER,
  BANNERS_ECPM,

  APP_OPEN_TOTAL,
  APP_OPEN_REV_TOTAL,
  APP_OPEN_REV_PER_USER,
  APP_OPEN_PER_USER,
  APP_OPEN_ECPM,

  REWARDED_INTERS_TOTAL,
  REWARDED_INTERS_REV_TOTAL,
  REWARDED_INTERS_REV_PER_USER,
  REWARDED_INTERS_PER_USER,
  REWARDED_INTERS_ECPM,
  ADS_REVENUE_PER_USER,
  ADS_REVENUE_TOTAL,
  IAP_REVENUE_PER_USER,
  IAP_REVENUE_TOTAL,
  USAGE_PER_USER,
  USAGE_TOTAL,

  RATE_LOW,
  RATE_HIGH,
  LOSS,
  GAIN,

  IMPR_PERCENT,
  IMPR_LOW_PERCENT,
  IMPR_HIGH_PERCENT,
  EFFECT_SIZE_HDI
];

export const metricDefaultKeys = [
  USERS,
  KPI_RATE,
  INTERS_PER_USER,
  USAGE_PER_USER,
  BANNERS_PER_USER,
  RVS_PER_USER,
  INTERS_PER_MINUTE,
  ILDR_ECPM,
  BANNERS_ECPM,
  RVS_ECPM,
  INTERS_TOTAL,
  BANNERS_TOTAL,
  RVS_TOTAL,
  USAGE_TOTAL,
  NUMBER_OF_COHORTS,
  GAIN_PERCENT,
  LOSS_PERCENT,
  PROB_TO_BEAT_CONTROL,
  PROB_TO_BE_BEST,
  PROB_BETTER,
  PROB_EQUAL,
  PROB_WORSE,
  EFFECT_SIZE_HDI,
  IAP_REVENUE_PER_USER,
  IAP_REVENUE_TOTAL
];

export const metricTitleMap = {
  [REGION]: 'Region',
  [IMPR_HIGH_PERCENT]: 'Improvement High  %',
  [IMPR_LOW_PERCENT]: 'Improvement Low %',

  [ILDR_ECPM]: 'Inter eCPM',
  [BANNERS_ECPM]: 'Banners eCPM',
  [RVS_ECPM]: 'RVs eCPM',
  [APP_OPEN_ECPM]: 'AppOpen eCPM',
  [REWARDED_INTERS_ECPM]: 'Rewaded Inters eCPM',
  [INTERS_PER_MINUTE]: 'Inters per minute',
  [INTERS_PER_USER]: 'Inters per User',
  [USAGE_PER_USER]: 'Usage per User',
  [BANNERS_PER_USER]: 'Banners per User',
  [RVS_PER_USER]: 'RVs per User',
  [APP_OPEN_PER_USER]: 'AppOpen per User',
  [REWARDED_INTERS_PER_USER]: 'Rewarded Inters per User',

  [INTERS_TOTAL]: 'Inters total',
  [BANNERS_TOTAL]: 'Banners total',
  [RVS_TOTAL]: 'RVs total',
  [APP_OPEN_TOTAL]: 'AppOpen total',
  [REWARDED_INTERS_TOTAL]: 'Rewarded Inters total',
  [USAGE_TOTAL]: 'Usage total',

  [INTERS_REV_PER_USER]: 'Inters Revenue per User',
  [BANNERS_REV_PER_USER]: 'Banners Revenue per User',
  [RVS_REV_PER_USER]: 'RVs Revenue per User',
  [APP_OPEN_REV_PER_USER]: 'AppOpen Revenue per User',
  [REWARDED_INTERS_REV_PER_USER]: 'Rewarded Inters Revenue per User',

  [ADS_REVENUE_TOTAL]: 'Ads Revenue total',
  [ADS_REVENUE_PER_USER]: 'Ads Revenue per User',
  [IAP_REVENUE_TOTAL]: 'IAP Revenue total',
  [IAP_REVENUE_PER_USER]: 'IAP Revenue per User',
  [INTERS_REV_TOTAL]: 'Inters Revenue total',
  [BANNERS_REV_TOTAL]: 'Banners Revenue total',
  [RVS_REV_TOTAL]: 'RVs Revenue total',
  [APP_OPEN_REV_TOTAL]: 'AppOpen Revenue total',
  [REWARDED_INTERS_REV_TOTAL]: 'Rewarded Inters Revenue total',

  [NUMBER_OF_COHORTS]: 'Number of cohorts',

  [IMPR_PERCENT]: 'Improvement %',
  [PROB_TO_BEAT_CONTROL]: 'Beat Control',
  [PROB_TO_BE_BEST]: 'Be Best',

  [PROB_BETTER]: 'Probably Better',
  [PROB_EQUAL]: 'Probably Equal',
  [PROB_WORSE]: 'Probably Worse',

  [GAIN_PERCENT]: 'Gain %',
  [LOSS_PERCENT]: 'Loss %',
  [LOSS]: 'Loss',
  [GAIN]: 'Gain',
  [GAIN_PERCENT]: 'Gain %',
  [EFFECT_SIZE_HDI]: 'HDI',
  [USERS]: 'Users',
  [KPI_RATE]: 'KPI Rate',
  [RATE_HIGH]: 'Rate High',
  [RATE_LOW]: 'Rate Low',
  [KPI_TOTAL]: 'KPI Total',
  [ARPU]: 'Arpu',
  [LIFT_PERCENTAGE]: 'Lift %',
  [T_STATISTIC]: 'T Statistic',
  [P_VALUE]: 'P Value',
  [POWER_PERCENTAGE]: 'Power %'
};

export const metricFormatMap = {
  [MetricDataType.INT]: (value) => formatNumber(value, 0),
  [MetricDataType.FLOAT]: (value) => formatNumber(value, 1),
  [MetricDataType.PERCENT]: (value) => formatPercent(value, 0),
  [MetricDataType.BOOL]: (value) => (value ? 'True' : 'False'),
  [MetricDataType.TEXT]: (value) => value
  /* Custom formatters */
};

export const metricMeta = {
  [REGION]: {
    key: REGION,
    type: MetricDataType.TEXT,
    title: metricTitleMap[REGION],
    format: metricFormatMap[MetricDataType.TEXT],
    isFixed: true
  },
  [IMPR_HIGH_PERCENT]: {
    key: IMPR_HIGH_PERCENT,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[IMPR_HIGH_PERCENT],
    format: metricFormatMap[MetricDataType.PERCENT]
  },
  [IMPR_LOW_PERCENT]: {
    key: IMPR_LOW_PERCENT,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[IMPR_LOW_PERCENT],
    format: metricFormatMap[MetricDataType.PERCENT]
  },
  [IMPR_PERCENT]: {
    key: IMPR_PERCENT,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[IMPR_PERCENT],
    format: metricFormatMap[MetricDataType.PERCENT]
  },
  [PROB_TO_BEAT_CONTROL]: {
    key: PROB_TO_BEAT_CONTROL,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[PROB_TO_BEAT_CONTROL],
    format: metricFormatMap[MetricDataType.PERCENT]
  },
  [PROB_TO_BE_BEST]: {
    key: PROB_TO_BE_BEST,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[PROB_TO_BE_BEST],
    format: metricFormatMap[MetricDataType.PERCENT]
  },

  [PROB_BETTER]: {
    key: PROB_BETTER,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[PROB_BETTER],
    format: metricFormatMap[MetricDataType.PERCENT]
  },
  [PROB_EQUAL]: {
    key: PROB_EQUAL,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[PROB_EQUAL],
    format: metricFormatMap[MetricDataType.PERCENT]
  },
  [PROB_WORSE]: {
    key: PROB_WORSE,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[PROB_WORSE],
    format: metricFormatMap[MetricDataType.PERCENT]
  },

  [LOSS_PERCENT]: {
    key: LOSS_PERCENT,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[LOSS_PERCENT],
    format: (value) => formatPercent(value, 2)
  },
  [LOSS]: {
    key: LOSS,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[LOSS],
    format: metricFormatMap[MetricDataType.FLOAT]
  },
  [GAIN]: {
    key: GAIN,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[GAIN],
    format: metricFormatMap[MetricDataType.FLOAT]
  },

  [KPI_RATE]: {
    key: KPI_RATE,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[KPI_RATE],
    format: (value) => formatNumber(value, 3)
  },

  [RATE_HIGH]: {
    key: RATE_HIGH,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[RATE_HIGH],
    format: metricFormatMap[MetricDataType.FLOAT]
  },

  [RATE_LOW]: {
    key: RATE_LOW,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[RATE_LOW],
    format: metricFormatMap[MetricDataType.FLOAT]
  },
  [USERS]: {
    key: USERS,
    type: MetricDataType.INT,
    title: metricTitleMap[USERS],
    format: metricFormatMap[MetricDataType.INT]
  },
  [EFFECT_SIZE_HDI]: {
    key: EFFECT_SIZE_HDI,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[EFFECT_SIZE_HDI],
    format: (value) => formatNumber(value, 3)
  },

  [KPI_TOTAL]: {
    key: KPI_TOTAL,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[KPI_TOTAL],
    format: (value) => formatNumber(value, 3)
  },

  [GAIN_PERCENT]: {
    key: GAIN_PERCENT,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[GAIN_PERCENT],
    format: (value) => formatPercent(value, 2)
  },

  [INTERS_PER_USER]: {
    key: INTERS_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[INTERS_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [INTERS_TOTAL]: {
    key: INTERS_TOTAL,
    type: MetricDataType.INT,
    title: metricTitleMap[INTERS_TOTAL],
    format: metricFormatMap[MetricDataType.INT]
  },

  [INTERS_REV_PER_USER]: {
    key: INTERS_REV_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[INTERS_REV_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [INTERS_REV_TOTAL]: {
    key: INTERS_REV_TOTAL,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[INTERS_REV_TOTAL],
    format: (value) => formatNumber(value, 3)
  },

  [ADS_REVENUE_PER_USER]: {
    key: ADS_REVENUE_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[ADS_REVENUE_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [ADS_REVENUE_TOTAL]: {
    key: ADS_REVENUE_TOTAL,
    type: MetricDataType.INT,
    title: metricTitleMap[ADS_REVENUE_TOTAL],
    format: (value) => formatNumber(value, 3)
  },

  [IAP_REVENUE_PER_USER]: {
    key: IAP_REVENUE_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[IAP_REVENUE_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [IAP_REVENUE_TOTAL]: {
    key: IAP_REVENUE_TOTAL,
    type: MetricDataType.INT,
    title: metricTitleMap[IAP_REVENUE_TOTAL],
    format: (value) => formatNumber(value, 3)
  },

  [ILDR_ECPM]: {
    key: ILDR_ECPM,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[ILDR_ECPM],
    format: (value) => formatNumber(value, 3)
  },

  [INTERS_PER_MINUTE]: {
    key: INTERS_PER_MINUTE,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[INTERS_PER_MINUTE],
    format: (value) => formatNumber(value, 3)
  },

  [RVS_PER_USER]: {
    key: RVS_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[RVS_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [RVS_TOTAL]: {
    key: RVS_TOTAL,
    type: MetricDataType.INT,
    title: metricTitleMap[RVS_TOTAL],
    format: metricFormatMap[MetricDataType.INT]
  },

  [RVS_REV_PER_USER]: {
    key: RVS_REV_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[RVS_REV_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [RVS_REV_TOTAL]: {
    key: RVS_REV_TOTAL,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[RVS_REV_TOTAL],
    format: (value) => formatNumber(value, 3)
  },

  [RVS_ECPM]: {
    key: RVS_ECPM,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[RVS_ECPM],
    format: (value) => formatNumber(value, 3)
  },

  [BANNERS_PER_USER]: {
    key: BANNERS_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[BANNERS_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [BANNERS_TOTAL]: {
    key: BANNERS_TOTAL,
    type: MetricDataType.INT,
    title: metricTitleMap[BANNERS_TOTAL],
    format: metricFormatMap[MetricDataType.INT]
  },

  [BANNERS_REV_PER_USER]: {
    key: BANNERS_REV_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[BANNERS_REV_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [BANNERS_REV_TOTAL]: {
    key: BANNERS_REV_TOTAL,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[BANNERS_REV_TOTAL],
    format: (value) => formatNumber(value, 3)
  },

  [BANNERS_ECPM]: {
    key: BANNERS_ECPM,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[BANNERS_ECPM],
    format: (value) => formatNumber(value, 3)
  },

  [APP_OPEN_PER_USER]: {
    key: APP_OPEN_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[APP_OPEN_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [APP_OPEN_TOTAL]: {
    key: APP_OPEN_TOTAL,
    type: MetricDataType.INT,
    title: metricTitleMap[APP_OPEN_TOTAL],
    format: metricFormatMap[MetricDataType.INT]
  },

  [APP_OPEN_REV_PER_USER]: {
    key: APP_OPEN_REV_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[APP_OPEN_REV_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [APP_OPEN_REV_TOTAL]: {
    key: APP_OPEN_REV_TOTAL,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[APP_OPEN_REV_TOTAL],
    format: (value) => formatNumber(value, 3)
  },

  [APP_OPEN_ECPM]: {
    key: APP_OPEN_ECPM,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[APP_OPEN_ECPM],
    format: (value) => formatNumber(value, 3)
  },

  [REWARDED_INTERS_PER_USER]: {
    key: REWARDED_INTERS_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[REWARDED_INTERS_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [REWARDED_INTERS_TOTAL]: {
    key: REWARDED_INTERS_TOTAL,
    type: MetricDataType.INT,
    title: metricTitleMap[REWARDED_INTERS_TOTAL],
    format: metricFormatMap[MetricDataType.INT]
  },

  [REWARDED_INTERS_REV_PER_USER]: {
    key: REWARDED_INTERS_REV_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[REWARDED_INTERS_REV_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [REWARDED_INTERS_REV_TOTAL]: {
    key: REWARDED_INTERS_REV_TOTAL,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[REWARDED_INTERS_REV_TOTAL],
    format: (value) => formatNumber(value, 3)
  },

  [REWARDED_INTERS_ECPM]: {
    key: REWARDED_INTERS_ECPM,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[REWARDED_INTERS_ECPM],
    format: (value) => formatNumber(value, 3)
  },

  [ARPU]: {
    key: ARPU,
    type: MetricDataType.INT,
    title: metricTitleMap[ARPU],
    format: metricFormatMap[MetricDataType.INT]
  },

  [LIFT_PERCENTAGE]: {
    key: LIFT_PERCENTAGE,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[LIFT_PERCENTAGE],
    format: (value) => formatPercent(value, 2)
  },

  [T_STATISTIC]: {
    key: T_STATISTIC,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[T_STATISTIC],
    format: (value) => formatNumber(value, 3)
  },

  [P_VALUE]: {
    key: P_VALUE,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[P_VALUE],
    format: (value) => formatNumber(value, 3)
  },

  [POWER_PERCENTAGE]: {
    key: POWER_PERCENTAGE,
    type: MetricDataType.PERCENT,
    title: metricTitleMap[POWER_PERCENTAGE],
    format: (value) => formatPercent(value, 2)
  },

  [USAGE_PER_USER]: {
    key: USAGE_PER_USER,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[USAGE_PER_USER],
    format: (value) => formatNumber(value, 3)
  },

  [USAGE_TOTAL]: {
    key: USAGE_TOTAL,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[USAGE_TOTAL],
    format: (value) => formatNumber(value, 3)
  },

  [NUMBER_OF_COHORTS]: {
    key: NUMBER_OF_COHORTS,
    type: MetricDataType.FLOAT,
    title: metricTitleMap[NUMBER_OF_COHORTS],
    format: metricFormatMap[MetricDataType.INT]
  }
};

/** @param {ExperimentVariableType} type */
export function mapVariableTypeToCustomType(type) {
  const map = {
    [ExperimentVariableType.BOOLEAN]: MetricDataType.BOOL,
    [ExperimentVariableType.INTEGER]: MetricDataType.INT,
    [ExperimentVariableType.STRING]: MetricDataType.TEXT,
    [ExperimentVariableType.INT_LIST]: MetricDataType.TEXT
  };

  return map[type];
}

/**
 * @param {string} key
 * @param {Record<string, ExperimentVariableDto>} variableMap
 */
export function getDynamicMetricMeta(key, variableMap) {
  const variable = variableMap[key];

  if (!variable) {
    return {
      key,
      type: MetricDataType.TEXT,
      title: key === DESCRIPTION ? 'Group Name' : key,
      format: metricFormatMap[MetricDataType.TEXT],
      isFixed: true
    };
  }

  const type = mapVariableTypeToCustomType(variable.type);

  return {
    key,
    type,
    title: variable.displayName,
    format: metricFormatMap[type],
    isFixed: true
  };
}
