import React from 'react';

import { TRow } from './TRow';
import { GameConfigForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';

type Props = {
  config: GameConfigForm[];
};

export function TBody({ config }: Props) {
  return (
    <tbody>
      {config.map((value) => (
        <TRow key={value.name} config={value} />
      ))}
    </tbody>
  );
}
