export enum DirectConfigState {
  IN_DEV = 'IN_DEV',
  LIVE = 'LIVE',
  STOPPED = 'STOPPED',
  CANCELED = 'CANCELED'
}

export const DirectConfigStateLabels: { [key in DirectConfigState]: string } = {
  [DirectConfigState.IN_DEV]: 'In Dev',
  [DirectConfigState.LIVE]: 'Running',
  [DirectConfigState.STOPPED]: 'Stopped',
  [DirectConfigState.CANCELED]: 'Canceled'
};
