import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash-es';

import {
  initAdProfileObjective,
  pushAdProfileObjectiveForm
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { RequestStage } from '@infrastructure/store/types/actions';
import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { AdProfileSummary } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/adProfileSummary/AdProfileSummary';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { ExperimentFormMapper } from '@app/mappers/experiment/ExperimentFormMapper';
import { useForm } from '@ui/hooks/form';
import { AdProfileObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/types/AdProfileObjectiveForm';
import { AdProfileForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/AdProfileForm';

type Props = {
  isCompleted?: boolean;
};

export function AdProfileFormContainer({ isCompleted }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initAdProfileObjective());
  }, [dispatch]);

  const controlGroups = useSelector(createDirectConfigSelectors.getControlGroups);
  const status = useSelector(createDirectConfigSelectors.getControlGroupsStatus);
  const isRequest = [RequestStage.REQUEST, RequestStage.NONE].includes(status);

  const variables = useSelector(configSelectors.getVariableList);
  const { adProfileObjective } = useSelector(createDirectConfigSelectors.getForm);
  const isClone = useSelector(createDirectConfigSelectors.isClone);

  const defaultControlGroups = ExperimentFormMapper.mapDefaultControlGroups(variables, controlGroups.Default);

  let form = {} as AdProfileObjectiveForm;

  if (isClone) {
    form = adProfileObjective.mergeControlGroup(defaultControlGroups[0]);
  } else {
    form = adProfileObjective.setControlGroups(defaultControlGroups);
  }

  const defaultValues = cloneDeep(form);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<AdProfileObjectiveForm>({
    schema: AdProfileObjectiveForm,
    defaultValues
  });

  const handleNext = handleSubmit((params) => {
    dispatch(pushAdProfileObjectiveForm.trigger(params));
  });

  if (isRequest) {
    return null;
  }

  if (isCompleted) {
    return <AdProfileSummary />;
  }

  return (
    <AdProfileForm<AdProfileObjectiveForm>
      errors={errors}
      register={register}
      control={control}
      handleSubmit={handleNext}
      defaultControlGroup={defaultControlGroups[0]}
      disabled={!isValid}
    />
  );
}
