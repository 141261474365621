import React from 'react';

import { ExperimentType } from '@domain/enums/ExperimentType';
import { ExperimentState } from '@domain/enums/ExperimentState';

import { HeaderContainer } from '@pages/experimentDetails/components/header/HeaderContainer';
import { SummaryContainer } from '@pages/experimentDetails/components/summary/SummaryContainer';
import { WinnerStatusContainer } from '@pages/experimentDetails/components/winnerStatus/WinnerStatusContainer';
import { SanityChecksContainer } from '@pages/experimentDetails/components/sanityChecks/SanityChecksContainer';
import { StatsContainer } from '@pages/experimentDetails/components/stats/StatsContainer';

import styles from '@pages/experimentDetails/ExperimentDetails.module.scss';

type Props = {
  experimentType: ExperimentType;
  state: ExperimentState;
};

export default function ExperimentDetails({ experimentType, state }: Props) {
  const isInDev =
    experimentType === ExperimentType.GLD_TEST &&
    [ExperimentState.IN_DEV, ExperimentState.SCHEDULED_IN_DEV].includes(state);

  return (
    <>
      <HeaderContainer />
      <section className={styles.section}>
        <SummaryContainer />
        {!isInDev && (
          <>
            <WinnerStatusContainer />
            <SanityChecksContainer />
            <StatsContainer />
          </>
        )}
      </section>
    </>
  );
}
