import { parseInt } from 'lodash-es';

import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ObjectiveMapper } from '@app/mappers/experiment/ObjectiveMapper';
import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';
import { ControlGroupSessionForm } from '@domain/models/createExperiment/ObjectiveConfigParams';

export class AdProfileFormMapper {
  static formatFormValue(value: string, type: ExperimentVariableType): any {
    switch (type) {
      case ExperimentVariableType.INTEGER:
        return parseInt(value);
      case ExperimentVariableType.BOOLEAN:
        return value === '1';
      case ExperimentVariableType.INT_LIST:
        return value.split(',').map(Number);
      case ExperimentVariableType.STRING:
      default:
        return value;
    }
  }

  static toGenericConfig(
    params: string[],
    controlGroup: ControlGroupSessionForm[],
    variables: ExperimentVariableDto[]
  ): GenericConfigEntry {
    const entry = params.reduce((acc, param) => {
      const variable = variables.find(({ name }) => name === param);

      if (!variable) {
        return acc;
      }

      const { sessionsSupported, type } = variable;

      const sessionType = sessionsSupported
        ? ExperimentObjectiveSessionType.SESSION
        : ExperimentObjectiveSessionType.PLAIN;

      const wrapperType = ObjectiveMapper.mapVariableTypeToWrapperType(type);

      const formattedValue = controlGroup.reduce((sessionAcc, session) => {
        const value = session[param];
        if (value) {
          sessionAcc[session.sessionIndex] = {
            type: wrapperType,
            value: this.formatFormValue(value, type)
          };
        }
        return sessionAcc;
      }, {});

      const configValue = sessionsSupported
        ? formattedValue
        : { type: wrapperType, value: this.formatFormValue(controlGroup[0][param], type) };

      return {
        ...acc,
        [param]: {
          type: sessionType,
          configValue
        }
      };
    }, {});

    return {
      name: 'A',
      description: null,
      active: true,
      files: null,
      zipFileInfo: null,
      entry
    };
  }

  static formatToForm(value: any, type: ExperimentVariableType): string {
    switch (type) {
      case ExperimentVariableType.INTEGER:
        return value.toString();
      case ExperimentVariableType.BOOLEAN:
        return value ? '1' : '0';
      case ExperimentVariableType.INT_LIST:
        return value.join(',');
      case ExperimentVariableType.STRING:
      default:
        return value;
    }
  }

  static genericConfigToForm(
    result: GenericConfigEntry,
    variables: ExperimentVariableDto[]
  ): ControlGroupSessionForm[] {
    const sessions: { [key: string]: { [key: string]: string } } = {};

    Object.keys(result.entry).forEach((param) => {
      const variable = variables.find(({ name }) => name === param);

      if (!variable) {
        return;
      }

      const { sessionsSupported, type } = variable;
      const configValue = result.entry[param].configValue;

      if (sessionsSupported) {
        Object.keys(configValue).forEach((sessionIndex) => {
          if (!sessions[sessionIndex]) {
            sessions[sessionIndex] = { sessionIndex };
          }
          sessions[sessionIndex][param] = this.formatToForm(configValue[sessionIndex].value, type);
        });
      } else {
        if (!sessions['1']) {
          sessions['1'] = { sessionIndex: '1' };
        }
        sessions['1'][param] = this.formatToForm(configValue.value, type);
      }
    });

    return Object.values(sessions) as ControlGroupSessionForm[];
  }
}
