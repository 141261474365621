import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'crazy-ui-next';
import { memoize } from 'proxy-memoize';
import { cloneDeep } from 'lodash-es';

import { useForm } from '@ui/hooks/form';
import { useDispatch } from '@ui/hooks/redux';
import { pushInDevUpdate } from '@infrastructure/store/directConfigDetails/directConfigDetailsActions';
import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';
import { InDevFormParams } from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';
import { ConfigSection } from '@pages/directConfigDetails/components/summary/config/atoms/ConfigSection';
import { BasicInfoSection } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/BasicInfoSection';
import { TargetingConfigSection } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/targetingConfig/TargetingConfigSection';
import { RequestStage } from '@infrastructure/store/types/actions';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { ExperimentFormMapper } from '@app/mappers/experiment/ExperimentFormMapper';
import { AdProfileForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/AdProfileForm';
import { DirectConfigType } from '@domain/enums/directConfig/DirectConfigType';
import { ConfigInputContainer } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/objectiveConfig/configInput/ConfigInputContainer';

import styles from '@pages/directConfigDetails/components/summary/config/inDevForm/InDevForm.module.scss';

export function InDevFormContainer() {
  const dispatch = useDispatch();

  const directConfig = useSelector(memoize(directConfigDetailsSelectors.getDirectConfig));
  const controlGroups = useSelector(directConfigDetailsSelectors.getControlGroups);
  const variables = useSelector(configSelectors.getVariableList);
  const defaultControlGroups = ExperimentFormMapper.mapDefaultControlGroups(variables, controlGroups.Default);

  const initForm = new InDevFormParams(directConfig, variables);
  initForm.mergeControlGroup(defaultControlGroups[0]);
  const form = cloneDeep(initForm);
  const status = useSelector(directConfigDetailsSelectors.getDirectConfigStatus);
  const userProperties = useSelector(configSelectors.getUserProperties);
  const userPropertyOperators = useSelector(configSelectors.getUserPropertyOperators);

  const isLoading = status === RequestStage.REQUEST;
  const isAdProfile = directConfig.type === DirectConfigType.AD_PROFILE;

  const {
    register,
    formState: { errors, isValid },
    resetField,
    control,
    watch,
    handleSubmit,
    setFocus,
    setValue
  } = useForm<InDevFormParams>({
    schema: InDevFormParams,
    defaultValues: form
  });

  const handleFormSubmit = handleSubmit((params) => {
    dispatch(pushInDevUpdate.trigger(params));
  });

  return (
    <div className={styles.config}>
      <main>
        <ConfigSection className={styles.fullWidth} label="Basic Info:">
          <BasicInfoSection register={register} errors={errors} />
        </ConfigSection>
        <ConfigSection className={styles.fullWidth} label="Objective Config:">
          {isAdProfile ? (
            <AdProfileForm<InDevFormParams>
              errors={errors}
              register={register}
              control={control}
              handleSubmit={() => {}}
              defaultControlGroup={defaultControlGroups[0]}
              disabled={!isValid}
              showNextButton={false}
            />
          ) : (
            <ConfigInputContainer watch={watch} control={control} setFocus={setFocus} />
          )}
        </ConfigSection>
        <ConfigSection className={styles.fullWidth} label="Targeting Config:">
          <TargetingConfigSection
            control={control}
            register={register}
            resetField={resetField}
            watch={watch}
            setValue={setValue}
            userProperties={userProperties}
            operators={userPropertyOperators}
          />
        </ConfigSection>
        <div className={styles.formControls}>
          <Button onClick={handleFormSubmit} isLoading={isLoading} disabled={!isValid}>
            Save
          </Button>
        </div>
      </main>
    </div>
  );
}
