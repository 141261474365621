import React, { useCallback, useEffect, useState } from 'react';
import {
  Control,
  FieldValues,
  Path,
  useFieldArray,
  UseFormRegister,
  useWatch,
  ArrayPath,
  FieldArray
} from 'react-hook-form';
import { TextInput } from 'crazy-ui-next';

import { VersionOperator } from '@domain/enums/VersionOperator';
import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';
import { getInputProps } from '@ui/hooks/form';

import styles from '@components/layout/form/versionWithOperatorInput/VersionWithOperatorInput.module.scss';

type Props<T extends FieldValues> = {
  register: UseFormRegister<T>;
  control: Control<T>;
};

export function VersionsInput<T extends FieldValues>({ control, register }: Props<T>) {
  const versionOperator = useWatch<T>({ control, name: 'appliedOperator' as Path<T> });
  const [maxVersions, setMaxVersions] = useState(5);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'versions' as ArrayPath<T>
  });

  const onAdd = useCallback(() => {
    const newValue = { value: '' } as FieldArray<T, ArrayPath<T>>;
    append(newValue);
  }, [append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  useEffect(() => {
    if (versionOperator === VersionOperator.EQUAL && maxVersions !== 5) {
      setMaxVersions(5);
    }

    if (versionOperator !== VersionOperator.EQUAL && maxVersions === 5) {
      const indexesToRemove = fields.slice(1).map((_, index) => index + 1);

      remove(indexesToRemove);
      setMaxVersions(1);
    }
  }, [versionOperator, maxVersions, fields, remove, setMaxVersions]);

  const inputs = fields.map((field, index) => {
    return (
      <TextInput
        className={styles.version}
        key={field.id}
        {...getInputProps<T>(`versions.${index}.value` as Path<T>, register, {})}
      />
    );
  });

  return (
    <MultiTextInputContainer
      inputs={inputs}
      onAddItem={onAdd}
      onRemoveItem={onRemove}
      maxItems={maxVersions}
      className={styles.multiWrapper}
    />
  );
}
