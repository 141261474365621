import React from 'react';
import { InputLabel, TextInput } from 'crazy-ui-next';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/ControlGroup.module.scss';
import { DIGITS_AND_COMMA_REGEXP, EMPTY_STRING_REGEXP } from '@domain/constants';

type Props<T extends FieldValues> = Pick<FormComponent<T>, 'control'> & {
  index: number;
  name: string;
  labelText: string;
  min: number | boolean | string;
  max: number | boolean | string;
};

export function ControlledIntegerListInput<T extends FieldValues>({
  control,
  name,
  labelText,
  index,
  min,
  max
}: Props<T>) {
  return (
    <Controller
      name={`controlGroup.${index}.${name}` as Path<T>}
      control={control}
      render={({ field }) => {
        const handleBlurTweak = () => {
          const integers = field.value?.split(',').filter(Boolean);

          const formattedIntegers = integers.map((int) => {
            if (!int) {
              return min.toString();
            }
            if (int < min) {
              return min.toString();
            }
            if (int > max) {
              return max.toString();
            }
            return int;
          });
          const formattedValue = formattedIntegers.join(',');
          field.onChange(formattedValue);
        };

        const handleChange = (e) => {
          const { value } = e.currentTarget;

          (DIGITS_AND_COMMA_REGEXP.test(value) || EMPTY_STRING_REGEXP.test(value)) && field.onChange(value);
        };
        return (
          <>
            <InputLabel labelText={labelText} className={styles.label} />
            <TextInput
              name={field.name}
              type="text"
              value={field.value}
              className={styles.input}
              onChange={handleChange}
              onBlur={handleBlurTweak}
            />
          </>
        );
      }}
    />
  );
}
