import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';
import { ExperimentVariableInputMode } from '@domain/enums/ExperimentVariableInputMode';

type ControlValue = string;
type InputValue = { value: string }[] | string;

// todo tech-dept provide return typedef
export class ObjectiveFormatter {
  static formatControlValue(type: ExperimentVariableWrapper, value: ControlValue): any {
    switch (type) {
      case ExperimentVariableWrapper.STRING:
        return { value, type };
      case ExperimentVariableWrapper.INT:
        return { value: Number(value), type };
      case ExperimentVariableWrapper.INT_LIST:
        return { value: value.split(',').map((item) => Number(item)), type };
      case ExperimentVariableWrapper.BOOLEAN:
        return { value: Number(value), type };
      default:
        throw new TypeError('Unable to format control group value');
    }
  }

  static formatInputValue(type: ExperimentVariableInputMode, value: InputValue): any {
    switch (type) {
      case ExperimentVariableInputMode.STRING:
        return ObjectiveFormatter.formatString(value, type);
      case ExperimentVariableInputMode.INT:
        return ObjectiveFormatter.formatInt(value, type);
      case ExperimentVariableInputMode.INT_LIST:
        return ObjectiveFormatter.formatIntList(value, type);
      case ExperimentVariableInputMode.BOOLEAN:
        return ObjectiveFormatter.formatBoolean(value, type);
      default:
        throw new TypeError('Unable to format input value');
    }
  }

  static formatString(value: InputValue, type: ExperimentVariableInputMode): any {
    const isArray = Array.isArray(value);

    return isArray ? value.map((item) => ({ value: item.value, type })) : { value, type };
  }

  static formatInt(value: InputValue, type: ExperimentVariableInputMode): any {
    const isArray = Array.isArray(value);

    return isArray ? value.map((item) => ({ value: Number(item.value), type })) : { value: Number(value), type };
  }

  static formatIntList(value: InputValue, type: ExperimentVariableInputMode): any {
    const isArray = Array.isArray(value);

    return isArray
      ? value.map((item) => ({ values: item.value.split(',').map(Number), type }))
      : { values: value.split(',').map(Number), type };
  }

  static formatBoolean(value: InputValue, type: ExperimentVariableInputMode): any {
    const isArray = Array.isArray(value);
    const mapInputValueToBoolean = (input: string | number): any => {
      switch (input) {
        case '0':
        case '1':
          return { value: Number(input), type };
        case '2':
          return [
            { value: 0, type },
            { value: 1, type }
          ];
        default:
          throw new TypeError('Unable to map boolean value');
      }
    };

    if (isArray) {
      return value.map((item) => mapInputValueToBoolean(item.value));
    }

    return mapInputValueToBoolean(value);
  }

  static formatBooleanWrapperToString(value: boolean): string {
    return value ? '1' : '0';
  }

  static formatIntWrapperToString(value: number): string {
    return value.toString();
  }

  static formatIntListWrapperToString(value: number[]): string {
    return value.join(',');
  }

  static formatBooleanInputModeToValue(value: boolean): string {
    return value ? '1' : '0';
  }

  static formatIntInputModeToValue(value: number): { value: string } {
    return { value: value.toString() };
  }

  static formatIntListInputModeToValue(value: number[]): { value: string } {
    return { value: value.join(',') };
  }

  static formatFileValueText(filename: string) {
    const filenameParts = filename.split('/');

    // cloned filename will always contain tempId and other params
    const isClonedFile = filenameParts.length > 1;

    return isClonedFile ? filenameParts[filenameParts.length - 1] : filename;
  }
}
