import { combineReducers } from '@reduxjs/toolkit';

import { basicInfoReducer } from '@infrastructure/store/createDirectConfig/reducers/basicInfoReducer';
import { targetConfigReducer } from '@infrastructure/store/createDirectConfig/reducers/targetConfigReducer';
import { objectiveGLDConfigReducer } from '@infrastructure/store/createDirectConfig/reducers/objectiveGameConfigReducer';
import { objectiveAdProfileReducer } from '@infrastructure/store/createDirectConfig/reducers/objectiveAdProfileReducer';
import { formStepReducer } from '@infrastructure/store/createDirectConfig/reducers/formStepReducer';
import { regionsReducer } from '@infrastructure/store/createDirectConfig/reducers/regionsReducer';
import { gameStatsReducer } from '@infrastructure/store/createDirectConfig/reducers/gameStatsReducer';
import { controlGroupsReducer } from '@infrastructure/store/createDirectConfig/reducers/controlGroupsReducer';
import { cloneReducer } from '@infrastructure/store/createDirectConfig/reducers/cloneReducer';
import { paramsReducer } from '@infrastructure/store/createDirectConfig/reducers/paramsReducer';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import {
  fetchObjectiveConfig,
  clearAll,
  pushDirectConfigCreate,
  generateTargetConfig,
  generateAdProfileObjective,
  initTargetConfig
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { CreateDirectConfigForm } from '@domain/enums/CreateDirectConfigForm';
import { liveConfigsReducer } from '@infrastructure/store/createDirectConfig/reducers/liveConfigsReducer';
import { configListReducer } from '@infrastructure/store/createDirectConfig/reducers/configListReducer';

export type DirectConfigFormState = ReturnType<typeof formReducer>;

const formReducer = combineReducers({
  [CreateDirectConfigForm.BASIC_INFO]: basicInfoReducer,
  [CreateDirectConfigForm.TARGET_CONFIG]: targetConfigReducer,
  [CreateDirectConfigForm.AD_PROFILE_OBJECTIVE]: objectiveAdProfileReducer,
  [CreateDirectConfigForm.GAME_CONFIG_OBJECTIVE]: objectiveGLDConfigReducer
});

export const createDirectConfig = combineReducers({
  status: ReducerFactory.makeStatus(
    [initTargetConfig, generateTargetConfig, fetchObjectiveConfig, generateAdProfileObjective, pushDirectConfigCreate],
    clearAll
  ),
  form: formReducer,
  formStep: formStepReducer,
  regions: regionsReducer,
  gameStats: gameStatsReducer,
  controlGroups: controlGroupsReducer,
  liveConfigs: liveConfigsReducer,
  clone: cloneReducer,
  params: paramsReducer,
  configList: configListReducer
});
