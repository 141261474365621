import React from 'react';

import { ExperimentFormFormatter } from '@app/mappers/experiment/ExperimentFormFormatter';
import { GameConfigForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';

type Props = {
  config: GameConfigForm;
};

export function TRow({ config }: Props) {
  return (
    <tr>
      {config.input.map((input) => (
        <td key={input.key} data-highlight={!input.value}>
          {ExperimentFormFormatter.formatGLDValue(input.value)}
        </td>
      ))}
    </tr>
  );
}
