import React, { useCallback, useState } from 'react';
import { Button, ButtonVariant, CheckboxInput, ModalWrapper } from 'crazy-ui-next';

import { ReactComponent as DeleteSvg } from '@assets/svg/trash.svg';

import styles from '@components/forms/fileUploadCell/FileUpload.module.scss';
import { ObjectiveFormatter } from '@app/mappers/experiment/ObjectiveFormatter';

type ListItemProps = {
  filename: string;
  index: number;
  isSelected: boolean;
  onCheckboxChange: (index: number) => void;
};

function FileListItem({ filename, index, isSelected, onCheckboxChange }: ListItemProps) {
  return (
    <li>
      <CheckboxInput name="file" checked={isSelected} onChange={() => onCheckboxChange(index)} />
      {ObjectiveFormatter.formatFileValueText(filename)}
    </li>
  );
}

type Props = {
  handleCloseModal: () => void;
  configName: string;
  fileList: string[];
  handleSubmit: (fileIndexes: number[]) => void;
};

export function FileUploadModal({ handleCloseModal, configName, fileList, handleSubmit }: Props) {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const handleRemoveFiles = useCallback(() => {
    handleSubmit(selectedItems);
    handleCloseModal();
  }, [handleSubmit, selectedItems, handleCloseModal]);

  const handleCheckboxChange = useCallback((index: number) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(index)
        ? prevSelectedItems.filter((itemIndex) => itemIndex !== index)
        : [...prevSelectedItems, index]
    );
  }, []);

  if (!fileList.length) {
    handleCloseModal();
    return null;
  }

  const isSubmitDisabled = !selectedItems.length;

  return (
    <ModalWrapper handleModal={handleCloseModal} className={styles.modal}>
      <div className={styles.header}>
        <p>
          Edit group <span>{configName}</span> files
        </p>
      </div>

      <ul>
        {fileList.map((filename, index) => (
          <FileListItem
            key={`${index}_${filename}`}
            filename={filename}
            index={index}
            isSelected={selectedItems.includes(index)}
            onCheckboxChange={handleCheckboxChange}
          />
        ))}
      </ul>

      <div className={styles.controls}>
        <Button variant={ButtonVariant.SECONDARY} onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button onClick={handleRemoveFiles} disabled={isSubmitDisabled} icon={<DeleteSvg />}>
          Remove selected files
        </Button>
      </div>
    </ModalWrapper>
  );
}
