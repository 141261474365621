import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushClone,
  pushGameConfigObjectiveForm
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { GameConfigObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';

const objectiveInitial: GameConfigObjectiveForm = new GameConfigObjectiveForm();

export const objectiveGLDConfigReducer = createReducer(objectiveInitial, (qb) => {
  qb.addCase(pushGameConfigObjectiveForm.trigger, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushClone.success, (state, { payload }) => {
    if (payload) {
      return payload.gameConfigObjective;
    }

    return state;
  });
  qb.addCase(clearAll, () => {
    return new GameConfigObjectiveForm();
  });
});
