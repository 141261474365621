import React from 'react';

import { GLDFormConfig } from '@domain/models/createExperiment/GLDConfigParams';
import { ExperimentFormFormatter } from '@app/mappers/experiment/ExperimentFormFormatter';
import { GLDGroupFilesSummaryCell } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/GLDFormSummary/atoms/GLDGroupFilesSummaryCell';

type Props = {
  config: GLDFormConfig;
};

export function TRow({ config }: Props) {
  return (
    <tr>
      <td>{config.name}</td>
      <td>{config.description}</td>
      {config.input.map((input) => (
        <td key={input.key} data-highlight={!input.value}>
          {Array.isArray(input.value) ? (
            <GLDGroupFilesSummaryCell filenames={input.value} />
          ) : (
            ExperimentFormFormatter.formatGLDValue(input.value)
          )}
        </td>
      ))}
    </tr>
  );
}
