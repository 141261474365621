import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';
import { ReactComponent as EditSvg } from '@assets/svg/edit.svg';

import styles from '@components/forms/fileUploadCell/FileUpload.module.scss';

type Props = {
  handleAddClick: () => void;
  isUploading: boolean;
  isFileListEmpty: boolean;
  handleOpenGroupFilesModal: () => void;
  isDisabled: boolean;
};

export function FileUploadCellControls({
  handleAddClick,
  isUploading,
  isFileListEmpty,
  handleOpenGroupFilesModal,
  isDisabled
}: Props) {
  const disabled = isDisabled || isUploading;

  return (
    <div className={styles.controls}>
      <Button variant={ButtonVariant.TERTIARY} onClick={handleAddClick} icon={<PlusSvg />} disabled={disabled} />
      {!isFileListEmpty && (
        <Button
          variant={ButtonVariant.TERTIARY}
          onClick={handleOpenGroupFilesModal}
          icon={<EditSvg />}
          disabled={disabled}
        />
      )}
    </div>
  );
}
