import { ZipFileInfo } from '@domain/models/GenericConfigEntry';

export class DevObjInput {
  constructor(key: string, value: string | null) {
    this.key = key;
    this.value = value;
  }

  key: string;
  value: string | string[] | null;
}

export class DevObjFormConfig {
  constructor(
    name: string,
    input: DevObjInput[],
    description: string,
    active: boolean,
    zipFileInfo: ZipFileInfo | null
  ) {
    this.name = name;
    this.description = description;
    this.input = input;
    this.active = active;
    this.zipFileInfo = zipFileInfo;
  }

  name: string;
  description: string;
  input: DevObjInput[];
  active: boolean;
  zipFileInfo: ZipFileInfo | null;
}

class Param {
  value: string;
}

export class DevObjConfigParams {
  defaultValue: string;
  params: Param[];
  config: DevObjFormConfig[];

  static ofInitial() {
    const form = new DevObjConfigParams();

    form.defaultValue = '';
    form.params = [];
    form.config = [];

    return form;
  }
}
