import React, { useCallback } from 'react';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import { TextInput } from 'crazy-ui-next';

import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';
import { getInputProps } from '@ui/hooks/form';

import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';

import styles from '../TargetingConfig.module.scss';

type Props = {
  control: Control<DevPhaseParams>;
  register: UseFormRegister<DevPhaseParams>;
  isDisabled: boolean;
};

const maxInputsLength = 5;

export function VersionsInput({ control, register, isDisabled }: Props) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'targetingConfig.appVersions'
  });

  const onAdd = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  const inputs = fields.map((field, index) => {
    return (
      <TextInput
        className={styles.multiInputEl}
        key={field.id}
        disabled={isDisabled}
        {...getInputProps<DevPhaseParams>(`targetingConfig.appVersions.${index}.value`, register, {})}
      />
    );
  });

  return (
    <li>
      <div className={styles.editorInputLabel}>
        <p>Versions:</p>
      </div>
      <MultiTextInputContainer
        inputs={inputs}
        onAddItem={onAdd}
        onRemoveItem={onRemove}
        maxItems={maxInputsLength}
        className={styles.inputWrapper}
        isDisabled={isDisabled}
      />
    </li>
  );
}
