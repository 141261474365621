import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  generateABObjectiveConfig,
  pushABObjectiveForm,
  pushExperimentInit
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';

const objectiveInitial: Record<string, ObjectiveConfigParams> = {};

export const objectiveABConfigReducer = createReducer(objectiveInitial, (qb) => {
  qb.addCase(pushABObjectiveForm, (state, { payload }) => {
    const { region, form } = payload;

    state[region] = form;
  });
  qb.addCase(generateABObjectiveConfig.success, (state, { payload }) => {
    return payload.form;
  });
  qb.addCase(pushExperimentInit.success, (state, { payload }) => {
    if (payload) {
      return payload.objectiveABConfig;
    }

    return state;
  });
  qb.addCase(clearAll, () => {
    return objectiveInitial;
  });
});
