import React from 'react';
import { FieldArray } from 'react-hook-form';
import { Tooltip } from 'crazy-ui';

import { FormComponent } from '@ui/hooks/form';
import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';
import { AppPropertyType } from '@domain/enums/AppPropertyType';
import { ReactComponent as HintSvg } from '@assets/svg/hint.svg';

import { AppPropertyPeriodicityInput } from '@pages/appProperties/AppPropertyPeriodicityInput';
import { AppPropertyInput } from '@pages/appProperties/AppPropertyInput';
import { AppPropertiesEmailInput } from '@pages/appProperties/AppPropertiesEmailInput';

import styles from './AppProperties.module.scss';

const getInputLabel = (name: string) =>
  name
    .split('.')
    .splice(2)
    .join(' ')
    .replace('experiment', 'Segments AB test targeting')
    .replace('aboldtest', 'Ad Profile Old')
    .replace('abtest', 'Ad Profile')
    .replace('gldtest', 'Game Config');

const formatDescription = (description: string) => {
  if (!description) {
    return '';
  }

  return description
    .replace('AB OLD', 'Ad Profile Old')
    .replace('AB', 'Ad Profile')
    .replace('GLD', 'Game Config')
    .replace('experiment', 'Segments AB test targeting')
    .replace('experiments', 'Segments AB tests targeting');
};

type Props = FormComponent<AppPropertyForm> & {
  field: FieldArray<AppPropertyForm>;
  index: number;
};

export function AppPropertiesField({ field, register, errors, index, handleSubmit, control }: Props) {
  const { name, type } = field;

  const formattedDescription = formatDescription(field.description);

  switch (type) {
    case AppPropertyType.PERIODICITY_STRING:
      return (
        <div className={styles.fieldRow}>
          <AppPropertyPeriodicityInput
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            index={index}
            labelText={getInputLabel(name)}
            className={styles.fieldInput}
            control={control}
          />
          <Tooltip text={formattedDescription}>
            <HintSvg />
          </Tooltip>
        </div>
      );
    case AppPropertyType.EMAILS_ARRAY:
      return (
        <div className={styles.fieldRow}>
          <AppPropertiesEmailInput
            index={index}
            label={getInputLabel(name)}
            control={control}
            hint={formattedDescription}
          />
        </div>
      );
    default:
      return (
        <div className={styles.fieldRow}>
          <AppPropertyInput
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            index={index}
            labelText={getInputLabel(name)}
            className={styles.fieldInput}
          />
          <Tooltip text={formattedDescription}>
            <HintSvg />
          </Tooltip>
        </div>
      );
  }
}
