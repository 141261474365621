import React from 'react';
import { useSelector } from 'react-redux';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ExperimentState } from '@domain/enums/ExperimentState';

import { ExperimentConfig } from '@pages/experimentDetails/components/summary/config/ExperimentConfig';
import { DevPhaseForm } from '@pages/experimentDetails/components/summary/config/devPhase/DevPhaseForm';

export function ExperimentConfigContainer() {
  const { state } = useSelector(experimentDetailsSelectors.getExperiment);

  const isInDev = [ExperimentState.IN_DEV, ExperimentState.SCHEDULED_IN_DEV].includes(state);

  if (isInDev) {
    return <DevPhaseForm />;
  }

  return <ExperimentConfig />;
}
