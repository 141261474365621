import { IsArray, IsOptional, IsString, ValidateNested } from 'class-validator';

import { Type } from 'class-transformer';
import { VersionInput } from '@domain/models/VersionInput';
import { UserPropertiesParams } from '@domain/models/createExperiment/userProperties/UserPropertiesParams';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { VersionOperator, VersionOperatorLabels } from '@domain/enums/VersionOperator';

export class RunningFormParams {
  constructor(directConfig?: IDirectConfig) {
    if (!directConfig) {
      return this;
    }
    this.versions = directConfig.appVersions.map((version) => ({ value: version }));

    const initUserProperties: UserPropertiesParams = { inputs: [] };

    this.userProperties = directConfig.userProperties
      ? directConfig.userProperties.reduce((acc, property) => {
          acc.inputs.push({
            operatorId: property.operatorId.toString(),
            value: property.value,
            userPropertyId: property.userPropertyId.toString(),
            userPropertyDisplayName: property.userPropertyDisplayName,
            operatorDisplayName: property.operatorDisplayName
          });
          return acc;
        }, initUserProperties)
      : initUserProperties;
    this.importedSegments = directConfig.importedSegments;
    this.appliedOperator = directConfig.appliedOperator;
  }

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => VersionInput)
  versions: VersionInput[];

  @IsString()
  @IsOptional()
  public importedSegments: string;

  @Type(() => UserPropertiesParams)
  public userProperties: UserPropertiesParams;

  public appliedOperator: VersionOperator;

  // todo [Tech debt] [CU-1642] move to separate mapper & replace everywhere
  private getUserPropertiesLabel(userProperties: UserPropertiesParams): string {
    const { inputs } = userProperties;
    const nonEmptyValues = inputs?.filter(({ value }) => value.trim().length);

    if (!nonEmptyValues.length) {
      return '-';
    }

    return nonEmptyValues
      .map(
        ({ userPropertyDisplayName, operatorDisplayName, value }) =>
          `${userPropertyDisplayName} ${operatorDisplayName} ${value}`
      )
      .join(', ');
  }

  getSummary(): { title: string; value: string }[] {
    return [
      {
        title: 'App version',
        value: `${VersionOperatorLabels[this.appliedOperator]} ${this.versions.map(({ value }) => value).join(' / ')}`
      },
      { title: 'Imported Segments', value: this.importedSegments || '-' },
      {
        title: 'User Properties',
        value: this.getUserPropertiesLabel(this.userProperties)
      }
    ];
  }
}
