import React from 'react';

import { ExperimentState } from '@domain/enums/ExperimentState';

import styles from './ExperimentStatus.module.scss';

type Props = {
  status: ExperimentState;
};

const mapStatusToLabel = (status: ExperimentState) => {
  const value = {
    [ExperimentState.RUNNING]: 'RUNNING',
    [ExperimentState.IN_DEV]: 'IN DEV',
    [ExperimentState.SCHEDULED]: 'SCHEDULED',
    [ExperimentState.SCHEDULED_IN_DEV]: 'SCHEDULED',
    [ExperimentState.CANCELED]: 'CANCELED',
    [ExperimentState.STOPPED]: 'STOPPED',
    [ExperimentState.ERROR]: 'ERROR'
  }[status];

  if (!value) {
    throw new TypeError(`Cannot map "${status}" to "LabelText"`);
  }

  return value;
};

export function ExperimentStatus({ status }: Props) {
  const labelText = mapStatusToLabel(status);

  return (
    <span data-status={status} className={styles.status}>
      {labelText}
    </span>
  );
}
