import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushAdProfileObjectiveForm,
  pushGameConfigObjectiveForm
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';

const configListInitial: GenericConfigEntry[] = [];

export const configListReducer = createReducer(configListInitial, (qb) => {
  qb.addCase(pushAdProfileObjectiveForm.success, (state, { payload }) => {
    return [payload];
  });
  qb.addCase(pushGameConfigObjectiveForm.success, (state, { payload }) => {
    return [payload];
  });
  qb.addCase(clearAll, () => {
    return configListInitial;
  });
});
