import React from 'react';
import { Control, UseFormResetField, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { TargetConfigParams } from '@pages/createDirectConfig/targetConfig/types/TargetConfigParams';
import { UserProperty } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';
import { LiveRegionsInput } from '@pages/createDirectConfig/targetConfig/inputs/LiveRegionsInput';
import { InDevRegionsInput } from '@pages/createDirectConfig/targetConfig/inputs/InDevRegionsInput';
import { VersionWithOperatorInput } from '@components/layout/form/versionWithOperatorInput/VersionWithOperatorInput';
import { NextButton } from '@components/layout/NextButton/NextButton';
import { GameStatsContainer } from '@pages/createDirectConfig/targetConfig/components/gameStats/GameStatsContainer';
import { FormComponent } from '@ui/hooks/form';
import { ImportedSegmentsInput } from '@pages/createDirectConfig/targetConfig/inputs/ImportedSegmentsInput';
import { UserPropertiesContainer } from '@pages/createExperiment/targetConfig/userProperties/UserPropertiesContainer';

import styles from '@pages/createDirectConfig/targetConfig/TargetConfig.module.scss';

type Props = FormComponent<TargetConfigParams> & {
  watch: UseFormWatch<TargetConfigParams>;
  control: Control<TargetConfigParams>;
  resetField: UseFormResetField<TargetConfigParams>;
  setValue: UseFormSetValue<TargetConfigParams>;
  isValid: boolean;
  userProperties: UserProperty[];
  operators: UserPropertyOperator[];
};

export function TargetConfig({
  isValid,
  handleSubmit,
  watch,
  control,
  register,
  resetField,
  setValue,
  userProperties,
  operators
}: Props) {
  return (
    <>
      <GameStatsContainer />
      <form className={styles.targetConfig} onSubmit={handleSubmit}>
        <LiveRegionsInput control={control} resetField={resetField} />
        <InDevRegionsInput control={control} resetField={resetField} />
        <VersionWithOperatorInput<TargetConfigParams>
          register={register}
          control={control}
          className={styles.versions}
        />
        <ImportedSegmentsInput register={register} />
        <UserPropertiesContainer<TargetConfigParams>
          control={control}
          watch={watch}
          setValue={setValue}
          userProperties={userProperties}
          operators={operators}
          watchName="userProperties.inputs"
        />

        <NextButton disabled={!isValid} />
      </form>
    </>
  );
}
