import React from 'react';
import { InputLabel, TextInput } from 'crazy-ui-next';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/ControlGroup.module.scss';

type Props<T extends FieldValues> = Pick<FormComponent<T>, 'control'> & {
  index: number;
  name: string;
  labelText: string;
};

export function ControlledTextInput<T extends FieldValues>({ control, name, labelText, index }: Props<T>) {
  return (
    <Controller
      name={`controlGroup.${index}.${name}` as Path<T>}
      control={control}
      render={({ field }) => {
        return (
          <>
            <InputLabel labelText={labelText} className={styles.label} />
            <TextInput name={field.name} value={field.value} className={styles.input} onChange={field.onChange} />
          </>
        );
      }}
    />
  );
}
