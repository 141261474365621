import { ExperimentState } from '@domain/enums/ExperimentState';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { BusinessLine } from '@domain/enums/BusinessLine';
import { Experiment, ExperimentKey } from '@domain/entities/Experiment';
import { PageSortDir, PageSortValue } from '@domain/models/Paginate';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { SortByEvent } from '@ui/types/shared';

export enum ExpStatusFilterValue {
  ALL = 'ALL',
  RUNNING = 'RUNNING',
  IN_DEV = 'IN_DEV',
  STOPPED = 'STOPPED',
  CANCELED = 'CANCELED'
}

/** @see GamePlatform */
export enum ExpPlatformFilterValue {
  ALL = 'ALL',
  IOS = 'IOS_PLATFORM',
  ANDROID = 'ANDROID_PLATFORM'
}

/** @see BusinessLine */
export enum ExpBusinessLineFilterValue {
  ALL = 'ALL',
  CASUAL = 'CASUAL',
  HYPER_CASUAL = 'HYPER_CASUAL'
}

/** @see ExperimentType */
export enum ExpTypeFilterValue {
  ALL = 'ALL',
  AB_TESTING = 'AB_TESTING',
  AB_OLD_USERS_TESTING = 'AB_OLD_USERS_TESTING',
  GLD_TEST = 'GLD_TEST'
}

export type ExpSortByPayload = SortByEvent<ExperimentKey>;

export const mapExpStatusValueToType = (key: ExpStatusFilterValue): ExperimentState[] => {
  const value = {
    [ExpStatusFilterValue.RUNNING]: [
      ExperimentState.SCHEDULED,
      ExperimentState.SCHEDULED_IN_DEV,
      ExperimentState.RUNNING,
      ExperimentState.ERROR
    ],
    [ExpStatusFilterValue.IN_DEV]: [ExperimentState.IN_DEV],
    [ExpStatusFilterValue.STOPPED]: [ExperimentState.STOPPED],
    [ExpStatusFilterValue.CANCELED]: [ExperimentState.CANCELED]
  }[key];

  if (!value) {
    throw new TypeError(`Cannot map "${key}" to "ExperimentState"`);
  }

  return value;
};

export const mapExpBusinessLineValueToType = (key: ExpBusinessLineFilterValue): BusinessLine => {
  const value = {
    [ExpBusinessLineFilterValue.CASUAL]: BusinessLine.CASUAL,
    [ExpBusinessLineFilterValue.HYPER_CASUAL]: BusinessLine.HYPER_CASUAL
  }[key];

  if (!value) {
    throw new TypeError(`Cannot map "${key}" to "BusinessLine"`);
  }

  return value;
};

export const mapExpPlatformValueToType = (key: ExpPlatformFilterValue): GamePlatform => {
  const value = {
    [ExpPlatformFilterValue.IOS]: GamePlatform.IOS,
    [ExpPlatformFilterValue.ANDROID]: GamePlatform.ANDROID
  }[key];

  if (!value) {
    throw new TypeError(`Cannot map "${key}" to "GamePlatform"`);
  }

  return value;
};

export const mapExpTypeValueToType = (key: ExpTypeFilterValue): ExperimentType => {
  const value = {
    [ExpTypeFilterValue.AB_TESTING]: ExperimentType.AB_TESTING,
    [ExpTypeFilterValue.AB_OLD_USERS_TESTING]: ExperimentType.AB_OLD_USERS_TESTING,
    [ExpTypeFilterValue.GLD_TEST]: ExperimentType.GLD_TEST
  }[key];

  if (!value) {
    throw new TypeError(`Cannot map "${key}" to "ExperimentType"`);
  }

  return value;
};

export const mapExpSortByPayloadToParams = (payload: ExpSortByPayload): PageSortValue<Experiment> => {
  if (payload.order === PageSortDir.ASC) {
    return payload.key;
  }

  return `${payload.key},${payload.order}`;
};
