import { IsArray, ArrayNotEmpty } from 'class-validator';
import { cloneDeep, merge } from 'lodash-es';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { ControlGroupSessionForm } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { AdProfileFormMapper } from '@app/mappers/directConfig/AdProfileFormMapper';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';

export class AdProfileObjectiveForm {
  constructor(directConfigForm?: IDirectConfig, variables?: ExperimentVariableDto[]) {
    if (!directConfigForm || !variables) {
      return this;
    }

    this.params = Object.keys(directConfigForm.genericConfigList[0].entry).map((key) => key);

    this.controlGroup = AdProfileFormMapper.genericConfigToForm(directConfigForm.genericConfigList[0], variables);
  }

  @IsArray()
  @ArrayNotEmpty()
  params: string[] = [];

  @IsArray()
  controlGroup: ControlGroupSessionForm[] = [{ sessionIndex: '1' }];

  public setControlGroups(controlGroups: ControlGroupSessionForm[]) {
    this.controlGroup = controlGroups;
    return this;
  }

  public mergeControlGroup(controlGroup: ControlGroupSessionForm) {
    this.controlGroup = this.controlGroup.map((group) => {
      const refGroup = cloneDeep(controlGroup);

      return merge(refGroup, group);
    });
    return this;
  }
}
