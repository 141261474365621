import React from 'react';
import { FieldValues, Path, useWatch } from 'react-hook-form';

import { NextButton } from '@components/layout/NextButton/NextButton';
import { AdProfileParamsInput } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/inputs/AdProfileParamsInput';
import { VariableList } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/variableList/VariableList';
import { FormComponent } from '@ui/hooks/form';
import { ControlGroup } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/controlGroup/ControlGroup';

import styles from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/AdProfileForm.module.scss';
import { ControlGroupSessionForm } from '@domain/models/createExperiment/ObjectiveConfigParams';

type Props<T extends FieldValues> = FormComponent<T> & {
  defaultControlGroup: ControlGroupSessionForm;
  disabled: boolean;
  showNextButton?: boolean;
};

export function AdProfileForm<T extends FieldValues>({
  register,
  control,
  handleSubmit,
  defaultControlGroup,
  disabled,
  showNextButton = true
}: Props<T>) {
  const params = useWatch({
    control,
    name: 'params' as Path<T>
  });

  if (!params) {
    return null;
  }

  const paramsExist = params.length > 0;

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <fieldset className={styles.fieldset}>
        <AdProfileParamsInput register={register} control={control} />
      </fieldset>
      {paramsExist && (
        <>
          <VariableList control={control} />
          <ControlGroup control={control} defaultControlGroup={defaultControlGroup} />
        </>
      )}
      {showNextButton && <NextButton disabled={disabled} />}
    </form>
  );
}
