import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushExperimentInit,
  pushGoalConfigForm
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';

const goalConfigInitial: GoalConfigParams = GoalConfigParams.ofInitial();

export const goalConfigReducer = createReducer(goalConfigInitial, (qb) => {
  qb.addCase(pushGoalConfigForm, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushExperimentInit.success, (state, { payload }) => {
    if (payload) {
      return payload.goalConfig;
    }

    return state;
  });
  qb.addCase(clearAll, () => {
    return GoalConfigParams.ofInitial();
  });
});
