import React from 'react';
import { InputLabel, TextInput } from 'crazy-ui-next';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/ControlGroup.module.scss';

type Props<T extends FieldValues> = Pick<FormComponent<T>, 'control'> & {
  index: number;
  name: string;
  labelText: string;
  min: number | boolean | string;
  max: number | boolean | string;
};

export function ControlledIntegerInput<T extends FieldValues>({ control, name, labelText, index, min, max }: Props<T>) {
  return (
    <Controller
      name={`controlGroup.${index}.${name}` as Path<T>}
      control={control}
      render={({ field }) => {
        const handleBlurTweak = () => {
          const integer = field.value?.split('.')[0];
          if (integer < min) {
            field.onChange(min.toString());
            return;
          }
          if (integer > max) {
            field.onChange(max.toString());
            return;
          }
          field.onChange(integer);
        };
        return (
          <>
            <InputLabel labelText={labelText} className={styles.label} />
            <TextInput
              name={field.name}
              type="number"
              value={field.value}
              className={styles.input}
              onChange={field.onChange}
              onBlur={handleBlurTweak}
            />
          </>
        );
      }}
    />
  );
}
