import React from 'react';

import { ExperimentState } from '@domain/enums/ExperimentState';
import { UserRoleType } from '@domain/enums/UserRoleType';
import { ReactComponent as CrossSvg } from '@assets/svg/cancel.svg';
import { ReactComponent as UndoSvg } from '@assets/svg/undo.svg';
import { ReactComponent as CopySvg } from '@assets/svg/copy.svg';
import { ReactComponent as PlaySvg } from '@assets/svg/play-rounded.svg';

import styles from '@pages/experimentDetails/components/actions/Actions.module.scss';

export enum ACTIONS {
  BACK = 'BACK',
  STOP = 'STOP',
  SCHEDULE_GLD = 'SCHEDULE_GLD',
  CLONE = 'CLONE'
}

export const ACTIONS_TITLE_MAP = {
  [ACTIONS.BACK]: 'Back',
  [ACTIONS.STOP]: 'Stop',
  [ACTIONS.CLONE]: 'Clone',
  [ACTIONS.SCHEDULE_GLD]: 'Go Live'
};

export const ACTIONS_ICON_MAP = {
  [ACTIONS.BACK]: <UndoSvg className={styles.icon} />,
  [ACTIONS.STOP]: <CrossSvg className={styles.highlightIcon} />,
  [ACTIONS.CLONE]: <CopySvg className={styles.icon} />,
  [ACTIONS.SCHEDULE_GLD]: <PlaySvg className={styles.icon} />
};

export function getActions(status: ExperimentState) {
  switch (status) {
    case ExperimentState.IN_DEV: {
      return [ACTIONS.SCHEDULE_GLD, ACTIONS.STOP, ACTIONS.CLONE, ACTIONS.BACK];
    }
    case ExperimentState.SCHEDULED: {
      return [ACTIONS.STOP, ACTIONS.CLONE, ACTIONS.BACK];
    }
    case ExperimentState.SCHEDULED_IN_DEV: {
      return [ACTIONS.STOP, ACTIONS.CLONE, ACTIONS.BACK];
    }
    case ExperimentState.RUNNING: {
      return [ACTIONS.STOP, ACTIONS.CLONE, ACTIONS.BACK];
    }
    case ExperimentState.ERROR: {
      return [ACTIONS.STOP, ACTIONS.CLONE, ACTIONS.BACK];
    }
    case ExperimentState.CANCELED: {
      return [ACTIONS.CLONE, ACTIONS.BACK];
    }
    case ExperimentState.STOPPED: {
      return [ACTIONS.CLONE, ACTIONS.BACK];
    }
    default: {
      return [ACTIONS.CLONE, ACTIONS.BACK];
    }
  }
}

export function getActionsByUserRole(status: ExperimentState, userRole: UserRoleType | null) {
  const buttons = getActions(status);

  if (userRole === UserRoleType.GAME_OWNER) {
    return buttons.filter((action) => ![ACTIONS.SCHEDULE_GLD, ACTIONS.STOP].includes(action));
  }

  return buttons;
}
