import React from 'react';
import { FieldArrayWithId, UseFormWatch } from 'react-hook-form';

import { EditableParamName } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/EditableParamName';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';

import styles from '../ConfigInput.module.scss';

type Props = {
  watch: UseFormWatch<DevPhaseParams>;
  params: FieldArrayWithId<DevPhaseParams, 'objectiveConfig.params'>[];
  handleRemoveParam: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleUpdateParam: (newParam: string, index: number) => void;
  isDisabled: boolean;
};

export function Thead({ params, handleRemoveParam, watch, handleUpdateParam, isDisabled }: Props) {
  return (
    <thead>
      <tr>
        <th />
        <th className={styles.checkboxCol}>Active</th>
        <th>Group Name</th>
        {params.map((param, index) => (
          <EditableParamName
            key={param.id}
            param={param.value}
            paramIndex={index}
            watch={watch}
            handleRemoveParam={handleRemoveParam}
            handleUpdateParam={handleUpdateParam}
            isDisabled={isDisabled}
          />
        ))}
      </tr>
    </thead>
  );
}
