import React, { useCallback, useRef } from 'react';

import { FormComponent } from '@ui/hooks/form';
import useModal from '@ui/hooks/useModal';

import { RequestStage } from '@infrastructure/store/types/actions';
import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { S3ConfigState } from '@infrastructure/store/config/reducers/fileUploadReducer';

import { FileUploadModal } from '@components/forms/fileUploadCell/FileUploadModal';
import { GLDGroupFilesSummaryCell } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/GLDFormSummary/atoms/GLDGroupFilesSummaryCell';
import { FileUploadCellControls } from '@components/forms/fileUploadCell/FileUploadCellControls';

import { SvgLoader } from '@components/layout/Loader';

import styles from '@components/forms/fileUploadCell/FileUpload.module.scss';

type SummaryProps = {
  value: string | string[] | null;
};

function ValueSummary({ value }: SummaryProps) {
  if (!value || !value.length) {
    return <span>Upload Files</span>;
  }
  if (typeof value === 'string') {
    return <span>Invalid Files</span>;
  }

  return <GLDGroupFilesSummaryCell filenames={value} />;
}

type Props = Pick<FormComponent<GLDConfigParams>, 'control' | 'watch'> & {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileList: string[];
  status: RequestStage;
  configName: string;
  handleSubmitRemove: (fileIndexes: number[]) => void;
  S3Config: S3ConfigState;
  isDisabled: boolean;
};

export function FileUploadCell({
  handleFileChange,
  status,
  fileList,
  configName,
  handleSubmitRemove,
  S3Config,
  isDisabled
}: Props) {
  const { showModal, handleOpenModal, handleCloseModal } = useModal();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const isFileListEmpty = fileList.length === 0;
  const isUploading = status === RequestStage.REQUEST && S3Config.currentUploadingConfigName === configName;

  const handleAddClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleOpenGroupFilesModal = useCallback(() => {
    handleOpenModal();
  }, [handleOpenModal]);

  return (
    <>
      <td className={styles.uploadCell} data-highlight={isFileListEmpty}>
        <input ref={fileInputRef} type="file" multiple onChange={handleFileChange} />
        {isUploading ? (
          <div className={styles.cellLoader}>
            <SvgLoader />
          </div>
        ) : (
          <ValueSummary value={fileList} />
        )}
        <FileUploadCellControls
          handleAddClick={handleAddClick}
          isUploading={isUploading}
          isFileListEmpty={isFileListEmpty}
          handleOpenGroupFilesModal={handleOpenGroupFilesModal}
          isDisabled={isDisabled}
        />
      </td>
      {showModal && (
        <FileUploadModal
          handleCloseModal={handleCloseModal}
          configName={configName}
          fileList={fileList}
          handleSubmit={handleSubmitRemove}
        />
      )}
    </>
  );
}
