import React from 'react';

import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { GenericConfigValue } from '@domain/models/GenericConfigValue';

type Props = {
  entry: GenericConfigValue;
};

export function TCell({ entry }: Props) {
  const { type, configValue } = entry;
  const isPlain = type === ExperimentObjectiveSessionType.PLAIN;

  if (isPlain) {
    return (
      <td>
        <div>
          <p>{configValue?.value?.toString()}</p>
        </div>
      </td>
    );
  }

  const list = Object.keys(configValue).map((session) => (
    <p key={session}>
      {session}: {String(configValue[session].value)}
    </p>
  ));

  return (
    <td>
      <div>{list}</div>
    </td>
  );
}
