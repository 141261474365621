import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { authSelectors } from '@infrastructure/store/auth/authSelectors';
import { Routing } from '@infrastructure/routing';
import { Actions, SplitButtonOption } from '@pages/directConfigDetails/components/actions/Actions';
import {
  ACTIONS,
  ACTIONS_ICON_MAP,
  ACTIONS_TITLE_MAP,
  getActionsByUserRole
} from '@pages/directConfigDetails/components/actions/config';
import { ConfirmModal } from '@components/layout/confirmModal/ConfirmModal';
import { RequestStage } from '@infrastructure/store/types/actions';
import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';
import { pushStateUpdate } from '@infrastructure/store/directConfigDetails/directConfigDetailsActions';
import { DirectConfigState } from '@domain/enums/directConfig/DirectConfigState';

export function ActionsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showStopModal, handleStopModal] = useState(false);
  const userRole = useSelector(authSelectors.getCurrentRole);

  const directConfig = useSelector(directConfigDetailsSelectors.getDirectConfig);
  const status = useSelector(directConfigDetailsSelectors.getActionsStatus);

  const isLoading = status === RequestStage.REQUEST;

  const { state, id, name } = directConfig;

  const handleOpenStopModal = useCallback(() => {
    handleStopModal(true);
  }, []);

  const handleCloseStopModal = useCallback(() => {
    handleStopModal(false);
  }, []);

  const handleStop = useCallback(() => {
    let stopState: DirectConfigState.STOPPED | DirectConfigState.CANCELED;

    switch (directConfig.state) {
      case DirectConfigState.IN_DEV:
        stopState = DirectConfigState.CANCELED;
        break;
      case DirectConfigState.LIVE:
        stopState = DirectConfigState.STOPPED;
        break;
      default:
        throw new Error('Unable to stop Segment Targeting');
    }

    dispatch(pushStateUpdate.trigger(stopState));
    handleCloseStopModal();
  }, [dispatch, handleCloseStopModal, directConfig]);

  const handleGoLive = useCallback(() => {
    dispatch(pushStateUpdate.trigger(DirectConfigState.LIVE));
  }, [dispatch]);

  const handleBack = useCallback(() => {
    history.push(Routing.getDirectConfigList());
  }, [history]);

  const handleClone = useCallback(() => {
    history.push(Routing.getCloneDirectConfig(id));
  }, [history, id]);

  const handleClick = useCallback(
    (e) => {
      const { value } = e.currentTarget;

      switch (value) {
        case ACTIONS.STOP:
          handleOpenStopModal();
          break;
        case ACTIONS.GO_LIVE:
          handleGoLive();
          break;
        case ACTIONS.CLONE:
          handleClone();
          break;
        case ACTIONS.BACK:
        default:
          handleBack();
          break;
      }
    },
    [handleOpenStopModal, handleGoLive, handleBack, handleClone]
  );

  const actions = getActionsByUserRole(state, userRole);
  const options: SplitButtonOption[] = [{ value: 'default', label: 'Actions' }];
  actions.forEach((action) => {
    options.push({ value: action, label: ACTIONS_TITLE_MAP[action], icon: ACTIONS_ICON_MAP[action] });
  });

  return (
    <>
      <Actions options={options} handleClick={handleClick} disabled={isLoading} />
      {showStopModal && (
        <ConfirmModal
          title="Stop Segment Targeting?"
          subtitle={`Are you sure you want to stop "${name}"?`}
          handleModal={handleCloseStopModal}
          handleConfirm={handleStop}
        />
      )}
    </>
  );
}
