import React from 'react';
import { useSelector } from '@ui/hooks/redux';

import { THead } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/adProfileSummary/atoms/THead';
import { TBody } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/adProfileSummary/atoms/TBody';

import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { configSelectors } from '@infrastructure/store/config/configSelectors';

import styles from './AdProfileSummary.module.scss';

export function AdProfileSummary() {
  const configList = useSelector(createDirectConfigSelectors.getConfigList);
  const variables = useSelector(configSelectors.getVariableList);

  return (
    <div className={styles.wrapper}>
      <table className={styles.table}>
        <THead controlGroup={configList[0]} variables={variables} />
        <TBody configList={configList} region={'region'} />
      </table>
    </div>
  );
}
