import React from 'react';
import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import cn from 'classnames';

import { VersionOperatorInput } from '@components/layout/form/versionWithOperatorInput/VersionOperatorInput';
import { VersionsInput } from '@components/layout/form/versionWithOperatorInput/VersionsInput';

import styles from '@components/layout/form/versionWithOperatorInput/VersionWithOperatorInput.module.scss';

type Props<T extends FieldValues> = {
  register: UseFormRegister<T>;
  control: Control<T>;
  className?: string;
  hideLabel?: boolean;
};

export function VersionWithOperatorInput<T extends FieldValues>({
  register,
  control,
  className,
  hideLabel = false
}: Props<T>) {
  return (
    <div className={cn(styles.inputWrapper, className)}>
      <VersionOperatorInput control={control} hideLabel={hideLabel} />
      <VersionsInput register={register} control={control} />
    </div>
  );
}
