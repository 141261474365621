import React, { useCallback, useEffect } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { isArray, uniq } from 'lodash-es';

import { useDispatch, useSelector } from '@ui/hooks/redux';
import { ArrayUtils } from '@app/utils/ArrayUtils';

import { RequestStage } from '@infrastructure/store/types/actions';
import { Notification } from '@infrastructure/api/notifications';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { uploadFilesToS3 } from '@infrastructure/store/config/configActions';

import { FileUploadCell } from '@components/forms/fileUploadCell/FileUploadCell';

type Props<T extends FieldValues> = {
  control: Control<T>;
  configName: string;
  fieldName: string;
  isDisabled?: boolean;
};

export function FileUploadCellContainer<T extends FieldValues>({
  control,
  configName,
  fieldName,
  isDisabled = false
}: Props<T>) {
  const dispatch = useDispatch();

  const fileUploadStatus = useSelector(configSelectors.getS3FileUploadStatus);
  const S3Config = useSelector(configSelectors.getS3Config);

  const { field } = useController({
    control,
    name: fieldName as Path<T>
  });

  const { value, onChange } = field;

  const isFileListExists = isArray(value);
  const filenameList: string[] = isFileListExists ? value : [];
  const isFileListEmpty = !isFileListExists || filenameList.length === 0;

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = event.target.files;

      if (!fileList) {
        return;
      }

      const files = Array.from(fileList);
      const fileNames = files.map((file) => file.name);
      const allFilenames = isFileListEmpty ? fileNames : [...value, ...fileNames];
      const nonUniqueFilenames = ArrayUtils.getNonUniqueStrings(allFilenames);

      if (nonUniqueFilenames.length > 0) {
        Notification.error(
          `File name should be unique. Following files were not added: ${nonUniqueFilenames.join(', ')}`
        );
        return;
      }

      dispatch(uploadFilesToS3.trigger({ files, configName, tempS3Id: S3Config.tempS3Id }));

      const updatedFilenames = uniq(allFilenames);
      onChange(updatedFilenames);

      // **Reset file input value to ensure future changes trigger onChange**
      event.target.value = '';
    },
    [dispatch, onChange, configName, S3Config, value, isFileListEmpty]
  );

  const handleSubmitRemove = useCallback(
    (fileIndexes: number[]) => {
      if (isFileListExists) {
        const updatedFiles = value.filter((_, index) => !fileIndexes.includes(index));
        onChange(updatedFiles);
      }
    },
    [onChange, value, isFileListExists]
  );

  useEffect(() => {
    if (fileUploadStatus === RequestStage.FAILURE && !isFileListEmpty) {
      onChange([]);
    }
  }, [value, onChange, fileUploadStatus, isFileListEmpty]);

  return (
    <FileUploadCell
      handleFileChange={handleFileChange}
      fileList={filenameList}
      status={fileUploadStatus}
      configName={configName}
      handleSubmitRemove={handleSubmitRemove}
      S3Config={S3Config}
      isDisabled={isDisabled}
    />
  );
}
