import React from 'react';
import { DropdownInput, DropdownOption } from 'crazy-ui-next';

import { Control, FieldValues, useController, Path } from 'react-hook-form';
import { VersionOperator, VersionOperatorLabels } from '@domain/enums/VersionOperator';

import styles from '@components/layout/form/versionWithOperatorInput/VersionWithOperatorInput.module.scss';

const options: DropdownOption<VersionOperator>[] = Object.values(VersionOperator).map((value) => ({
  value,
  label: VersionOperatorLabels[value]
}));

type Props<T extends FieldValues> = {
  control: Control<T>;
  hideLabel: boolean;
};

export function VersionOperatorInput<T extends FieldValues>({ control, hideLabel }: Props<T>) {
  const name = 'appliedOperator' as Path<T>;
  const { field } = useController<T>({ control, name });

  const labelText = !hideLabel ? 'Versions' : undefined;

  return (
    <DropdownInput
      className={styles.dropdown}
      name={name}
      value={field.value}
      options={options}
      labelText={labelText}
      handleChange={(event) => field.onChange(event.value)}
    />
  );
}
