import { isEqual, differenceWith, isEmpty, uniq } from 'lodash-es';

export class ArrayUtils {
  static isEqual(base: any[], compare: any[]): boolean {
    let result = true;
    compare.forEach((item) => {
      if (!base.includes(item)) {
        result = false;
      }
    });

    return result;
  }

  static isEqualObjects(base: object[], compare: object[]): boolean {
    const diff = differenceWith(base, compare, isEqual);

    return isEmpty(diff);
  }

  static includesObject(array: object[], object: object): boolean {
    const result = array.filter((item) => isEqual(item, object));
    return !isEmpty(result);
  }

  static getNonUniqueStrings(items: string[]): string[] {
    const counts = new Map<string, number>();

    for (const item of items) {
      counts.set(item, (counts.get(item) ?? 0) + 1);
    }

    const nonUniqueItems = items.filter((item) => (counts.get(item) ?? 0) > 1);

    // return filtered non-uniques
    return uniq(nonUniqueItems);
  }
}
