import React, { useCallback } from 'react';
import { Control, FieldValues, Path, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Button, ButtonVariant } from 'crazy-ui-next';
import { Tooltip } from 'crazy-ui';

import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';
import { UserProperty } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { UserPropertyTextInput } from '@pages/createExperiment/targetConfig/userProperties/inputs/UserPropertyTextInput';
import { UserPropertiesDropdownInput } from '@pages/createExperiment/targetConfig/userProperties/inputs/UserPropertiesDropdownInput';
import { UserPropertyOperatorInput } from '@pages/createExperiment/targetConfig/userProperties/inputs/UserPropertyOperatorInput';
import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';
import { ReactComponent as MinusSvg } from '@assets/svg/minus.svg';
import { ReactComponent as HintSvg } from '@assets/svg/hint.svg';

import styles from '@pages/createExperiment/targetConfig/userProperties/UserProperties.module.scss';

type Props<T extends FieldValues> = {
  userProperties: UserProperty[];
  operators: UserPropertyOperator[];
  watch: UseFormWatch<T>;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  inputIndex: number;
  onAddRow: (currentRowIndex: number) => void;
  onRemoveRow: (index: number) => void;
  isDisabled: boolean;
  watchName: Path<T>;
};

export function UserPropertiesInput<T extends FieldValues>({
  operators,
  userProperties,
  control,
  setValue,
  inputIndex,
  onAddRow,
  onRemoveRow,
  watch,
  watchName,
  isDisabled
}: Props<T>) {
  // @ts-ignore
  const inputs = watch(watchName);

  const handeRemove = useCallback(() => {
    onRemoveRow(inputIndex);
  }, [onRemoveRow, inputIndex]);

  const handleAdd = useCallback(() => {
    onAddRow(inputIndex);
  }, [onAddRow, inputIndex]);

  const isLastInput = inputIndex + 1 === inputs.length;
  const isEmptyPropertyValue = !inputs[inputIndex]['userPropertyId'];
  const isEmptyValue = !inputs[inputIndex]['value'];

  const showPlusButton = !inputs.length || (isLastInput && userProperties.length > inputIndex + 1);
  const disablePlusButton = isEmptyPropertyValue || isEmptyValue || isDisabled;

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputs}>
        <UserPropertiesDropdownInput
          userProperties={userProperties}
          control={control}
          setValue={setValue}
          inputIndex={inputIndex}
          watchName={watchName}
          isDisabled={isDisabled}
        />
        <UserPropertyOperatorInput
          operators={operators}
          control={control}
          setValue={setValue}
          inputIndex={inputIndex}
          disabled={isEmptyPropertyValue || isDisabled}
          watchName={watchName}
        />
        <UserPropertyTextInput
          control={control}
          inputIndex={inputIndex}
          disabled={isEmptyPropertyValue || isDisabled}
          watchName={watchName}
        />
        <Tooltip text="You can enter multiple values by separating them with commas (e.g., value1, value2, value3)">
          <HintSvg />
        </Tooltip>
      </div>
      <div className={styles.controls}>
        <Button
          className={styles.minus}
          variant={ButtonVariant.TERTIARY}
          onClick={handeRemove}
          icon={<MinusSvg />}
          disabled={isDisabled}
        />
        {showPlusButton && (
          <Button
            variant={ButtonVariant.TERTIARY}
            onClick={handleAdd}
            disabled={disablePlusButton}
            icon={<PlusSvg />}
          />
        )}
      </div>
    </div>
  );
}
