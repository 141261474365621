import { cloneDeep, isArray } from 'lodash-es';
import { UIConfigDto } from '@domain/models/UIConfigDto';
import { getTitleForExperimentType, getTitleForPlatforms } from '@modules/config/utils/getTitleFromKey';

import { VariableTitleMapper } from '@app/mappers/VariableTitleMapper';
import { AppConfig, AppConfigDto } from '@domain/models/local/AppConfig';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';
import {
  AppPropertyArpuArrayInput,
  AppPropertyEmailInput,
  AppPropertyForm,
  AppPropertyIntInput,
  AppPropertyPeriodicityInput,
  AppPropertySetLongInput
} from '@domain/models/appProperty/AppPropertyInput';
import { AppPropertyDto, AppPropertyValue } from '@domain/models/appProperty/AppPropertyDto';
import { AppPropertyType } from '@domain/enums/AppPropertyType';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { CONFIG_GROUP_NAME } from '@domain/enums/ConfigGroupName';
import { RecommendedProfile } from '@domain/models/RecommendedProfile';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ObjectiveMapper } from '@app/mappers/experiment/ObjectiveMapper';

type GetCustomTitle = (name: string) => string;

type AppPropertyInput =
  | AppPropertyIntInput
  | AppPropertyArpuArrayInput
  | AppPropertyEmailInput
  | AppPropertyPeriodicityInput
  | AppPropertySetLongInput;

export class ConfigMapper {
  private static getKeyList(values: AppConfig.Value[] | AppConfig.ValueComplex[]): string[] {
    const isArrayOfStrings = (values as any[]).every((key) => typeof key === 'string');

    if (isArrayOfStrings) {
      return values as string[];
    }

    return values.map((item) => item.name);
  }

  private static getOptionList(map: AppConfig.ValueMap): { label: string; value: string }[] {
    return Object.keys(map).map((key) => ({ label: map[key]?.title, value: key }));
  }

  private static getValueMap(
    values: AppConfig.Value[] | AppConfig.ValueComplex,
    getCustomTitle?: GetCustomTitle
  ): AppConfig.ValueMap {
    const map = {};

    values.forEach((value) => {
      if (value && typeof value === 'string') {
        map[value] = {
          title: typeof getCustomTitle === 'function' ? getCustomTitle(value) : VariableTitleMapper.fromKey(value)
        };
      } else {
        map[value.name] = { title: value.name, ...value };
      }
    });

    return map;
  }

  private static convertBasicVars(
    values: AppConfig.Value[] | AppConfig.ValueComplex[],
    getCustomTitle?: GetCustomTitle
  ) {
    const map = ConfigMapper.getValueMap(values, getCustomTitle);
    const options = ConfigMapper.getOptionList(map);
    const keys = ConfigMapper.getKeyList(values);
    return { keys, map, options };
  }

  static mapDtoToCustom(dto: UIConfigDto, games: GameApplicationDto[]): AppConfigDto {
    const {
      experimentTypes = [],
      periodicities = [],
      queryPlaceholders = [],
      platforms = [],
      kpis = [],
      appVersion,
      minUsersPerGroup,
      experimentVariables
    } = dto;
    // Iterative Optimization is outdated
    const filteredExperimentTypes = experimentTypes.filter((type) => type !== ExperimentType.ITERATIVE_OPTIMISATION);

    return {
      experimentTypes: ConfigMapper.convertBasicVars(filteredExperimentTypes, getTitleForExperimentType),
      periodicity: ConfigMapper.convertBasicVars(periodicities),
      queryPlaceholders: ConfigMapper.convertBasicVars(queryPlaceholders),
      platforms: ConfigMapper.convertBasicVars(platforms, getTitleForPlatforms),
      kpi: ConfigMapper.convertBasicVars(kpis), // Contains "complex" value
      experimentVariables, // config has "variables" property which is deprecated
      appVersion,
      minUsersPerGroup,
      games // TODO: Should be removed from settings, temp solution to keep backward compatibility
    };
  }

  static mapDtoToAppPropertyInput(dto: AppPropertyDto): AppPropertyInput {
    const getBodyByType = (type: AppPropertyType) => {
      switch (type) {
        case AppPropertyType.INT:
          return new AppPropertyIntInput();
        case AppPropertyType.SET_LONG:
          return new AppPropertySetLongInput();
        case AppPropertyType.ARPU_ARRAY:
          return new AppPropertyArpuArrayInput();
        case AppPropertyType.EMAILS_ARRAY:
          return new AppPropertyEmailInput();
        case AppPropertyType.PERIODICITY_STRING:
        default:
          return new AppPropertyPeriodicityInput();
      }
    };

    const body = getBodyByType(dto.type);

    const processValue = (propertyValue: AppPropertyValue) => {
      if (Array.isArray(propertyValue)) {
        return propertyValue.join(',');
      }

      return String(propertyValue);
    };

    if (!dto) {
      body.setDefaultValues();
    }

    body.name = dto.name;
    body.description = dto.description;
    body.enabled = dto.enabled;
    body.type = dto.type;
    body.value = processValue(dto.value);

    return body;
  }

  static mapAppPropertiesListToParams(list: AppPropertyInput[]): AppPropertyForm {
    const body = new AppPropertyForm();

    if (!list) {
      body.setDefaultValues();
    }

    body.property = [];

    list.forEach((property, index) => {
      body.property[index] = list[index];
    });

    return body;
  }

  static mapAppPropertiesParamsToDto(body: Partial<AppPropertyForm>): AppPropertyDto[] {
    const dto = body.property?.map(() => new AppPropertyDto());

    const processValue = (propertyValue: string | string[], propertyType: AppPropertyType) => {
      switch (propertyType) {
        case AppPropertyType.INT:
          return Number(propertyValue);
        case AppPropertyType.ARPU_ARRAY:
          if (!propertyValue || !isArray(propertyValue)) {
            return [];
          }
          return propertyValue.map((value) => Number(value));
        case AppPropertyType.SET_LONG:
          if (!Array.isArray(propertyValue)) {
            return [];
          }
          const numbers = propertyValue.map((value) => Number(value));
          const setNumbers = new Set(numbers);

          return Array.from(setNumbers);
        case AppPropertyType.EMAILS_ARRAY:
          return String(propertyValue).split(',');
        case AppPropertyType.PERIODICITY_STRING:
        default:
          return propertyValue;
      }
    };

    body.property?.forEach((property, index) => {
      if (dto) {
        dto[index].name = property.name;
        dto[index].value = processValue(property.value, property.type);
        dto[index].description = property.description;
        dto[index].enabled = property.enabled;
        dto[index].type = property.type;
      }
    });

    return dto || [];
  }

  static sortByName(configList: GenericConfigEntry[]): GenericConfigEntry[] {
    const clone = cloneDeep(configList);

    clone.sort((a) => {
      if (a.name === CONFIG_GROUP_NAME.CONTROL) {
        return -1;
      }
      return 0;
    });

    return clone;
  }

  static mapRecommendedProfileToConfigs(
    profile: RecommendedProfile,
    variables: ExperimentVariableDto[]
  ): GenericConfigEntry[] {
    const configList: GenericConfigEntry[] = [];

    Object.keys(profile).forEach((name) => {
      const controlGroup = profile[name];

      const entry = Object.keys(controlGroup).reduce((acc, key) => {
        const expVariable = variables.find((variable) => variable.name === key);

        if (!expVariable) {
          return acc;
        }

        const sessionType = expVariable.sessionsSupported
          ? ExperimentObjectiveSessionType.SESSION
          : ExperimentObjectiveSessionType.PLAIN;
        const wrapperType = ObjectiveMapper.mapVariableTypeToWrapperType(expVariable.type);

        const configValue = Object.keys(controlGroup[key]).reduce((config, session) => {
          config[session] = { type: wrapperType, value: controlGroup[key][session] };
          return config;
        }, {});

        acc[key] = { type: sessionType, configValue };

        return acc;
      }, {});

      const config = { name, description: '', active: true, entry, files: null, zipFileInfo: null };

      configList.push(config);
    });

    return configList;
  }
}
