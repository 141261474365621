import React, { useCallback } from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { RequestStage } from '@infrastructure/store/types/actions';
import { Routing } from '@infrastructure/routing';
import { pushDirectConfigCreate } from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { ConfirmLiveConfigsModal } from '@components/layout/confirmLiveConfigsModal/ConfirmLiveConfigsModal';
import { ReactComponent as CancelSvg } from '@assets/svg/cancel.svg';
import { ReactComponent as AcceptSvg } from '@assets/svg/accept.svg';
import useModal from '@ui/hooks/useModal';

import styles from '@pages/createDirectConfig/CreateDirectConfig.module.scss';

export function FormControls() {
  const { showModal, handleOpenModal, handleCloseModal } = useModal(false);
  const dispatch = useDispatch();
  const isFormReady = useSelector(createDirectConfigSelectors.getIsFormReady);
  const status = useSelector(createDirectConfigSelectors.getStatus);
  const isLoading = isFormReady && status === RequestStage.REQUEST;
  const liveConfigs = useSelector(createDirectConfigSelectors.getLiveConfigs);
  const isLiveConfigsExist = liveConfigs.length > 0;

  const handleStart = useCallback(() => {
    dispatch(pushDirectConfigCreate.trigger());
  }, [dispatch]);

  const handleComplete = isLiveConfigsExist ? handleOpenModal : handleStart;

  return (
    <fieldset className={styles.controls}>
      <Button href={Routing.getDirectConfigList()} variant={ButtonVariant.SECONDARY} icon={<CancelSvg />}>
        Cancel
      </Button>
      <Button
        variant={ButtonVariant.PRIMARY}
        icon={<AcceptSvg />}
        onClick={handleComplete}
        disabled={!isFormReady}
        isLoading={isLoading}
      >
        Complete
      </Button>
      {showModal && (
        <ConfirmLiveConfigsModal handleCancel={handleCloseModal} handleSubmit={handleStart} liveConfigs={liveConfigs} />
      )}
    </fieldset>
  );
}
