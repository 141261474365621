import React from 'react';
import { memoize } from 'proxy-memoize';

import { ExperimentState } from '@domain/enums/ExperimentState';
import { useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';

import styles from '../../../../ExperimentDetails.module.scss';

export function ExpDefinitionSummary() {
  const experiment = useSelector(memoize(experimentDetailsSelectors.getExperiment));
  const isRunning = experiment.state === ExperimentState.RUNNING;

  const params = useSelector(memoize(experimentDetailsSelectors.getExpDefinitionParams));
  const summary = params.getSummary();

  if (isRunning) {
    const liveRegions = experiment.experimentObjectives.map((x) => x.regions).flat();
    summary.push({ title: 'Targeting Regions', value: liveRegions.map((x) => x.name).join(', ') });
  }

  const items = summary.map(({ title, value }) => (
    <li key={title}>
      {title}:<span>{value}</span>
    </li>
  ));

  return (
    <div>
      <ul className={styles.summaryList}>{items}</ul>
    </div>
  );
}
