import React from 'react';
import { Control, useController, UseFormResetField } from 'react-hook-form';
import cn from 'classnames';

import { useSelector } from '@ui/hooks/redux';
import { RegionMapper } from '@app/mappers/experiment/RegionMapper';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';

import { RequestStage } from '@infrastructure/store/types/actions';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';

import { SelectInput } from '@components/lib/Select/SelectInput';

import styles from '../TargetingConfig.module.scss';

const inputName = 'targetingConfig.regions';

type Props = {
  control: Control<DevPhaseParams>;
  resetField: UseFormResetField<DevPhaseParams>;
  isDisabled: boolean;
};

export function LiveRegionsInput({ control, isDisabled }: Props) {
  const { field, formState } = useController({ control, name: inputName });

  const { experimentObjectives } = useSelector(experimentDetailsSelectors.getExperiment);

  const defaultRegions = useSelector(experimentDetailsSelectors.getDefaultRegionsData);
  const status = useSelector(experimentDetailsSelectors.getDefaultRegionsStatus);

  const isLoading = status === RequestStage.REQUEST;

  const { regions } = experimentObjectives[0];

  const options = RegionMapper.createOptions([...defaultRegions, ...regions]);

  const error = formState.errors?.targetingConfig?.regions?.message;

  return (
    <li>
      <div className={styles.editorInputLabel}>
        <p>Live Regions:</p>
      </div>
      <SelectInput
        labelText=""
        name="regions"
        placeholder="Select regions"
        value={field.value}
        options={options}
        handleChange={field.onChange}
        error={error}
        className={cn(styles.input, styles.dropdownInput)}
        isMulti
        isClearable
        isDisabled={isLoading || isDisabled}
      />
    </li>
  );
}
