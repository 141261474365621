import React, { useEffect } from 'react';
import { plainToInstance } from 'class-transformer';

import {
  fetchGameInstallsStats,
  pushGLDObjectiveForm,
  initGLDObjectiveConfig
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { appPropertiesSelectors } from '@infrastructure/store/applicationProperties/appPropertiesSelectors';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { GLDForm } from '@pages/createExperiment/objectiveConfig/forms/GLD/GLDForm';
import { GLDFormSummary } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/GLDFormSummary/GLDFormSummary';
import { useForm } from '@ui/hooks/form';
import { useDispatch, useSelector } from '@ui/hooks/redux';

type Props = {
  isCompleted?: boolean;
};

export function GLDFormContainer({ isCompleted }: Props) {
  const dispatch = useDispatch();

  const { targetConfig } = useSelector(createExperimentSelectors.getForm);
  const { objectiveGLDConfig } = useSelector(createExperimentSelectors.getForm);
  const isClone = useSelector(createExperimentSelectors.isClone);

  const defaultFormValues = GLDConfigParams.getDefaultValuesByObjectiveConfig(
    objectiveGLDConfig,
    isClone,
    targetConfig.userProperties,
    targetConfig.importedSegments
  );

  const {
    register,
    control,
    watch,
    handleSubmit,
    setFocus,
    setValue,
    formState: { errors }
  } = useForm<GLDConfigParams>({
    schema: GLDConfigParams,
    defaultValues: defaultFormValues
  });

  const { primaryRegion } = useSelector(createExperimentSelectors.getForm)[CreateExperimentForm.TARGET_CONFIG];
  const regionData = useSelector(createExperimentSelectors.getRegionByName(primaryRegion));
  const gameInstalls = useSelector(createExperimentSelectors.getGameInstalls)[primaryRegion];
  const minUsersPerGroup = useSelector(appPropertiesSelectors.getMinUsersPerGroup);
  const gldParams = useSelector(createExperimentSelectors.getGLDParams);

  useEffect(() => {
    dispatch(initGLDObjectiveConfig());
  }, [dispatch]);

  useEffect(() => {
    if (regionData) {
      dispatch(fetchGameInstallsStats.trigger(regionData));
    }
  }, [dispatch, regionData]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'newUsers' && type === 'change') {
        setValue('sticky', Boolean(value.newUsers));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleNext = handleSubmit((body) => {
    const form = plainToInstance(GLDConfigParams, body);

    dispatch(pushGLDObjectiveForm(form));
  });

  if (isCompleted) {
    return <GLDFormSummary />;
  }

  return (
    <GLDForm
      handleSubmit={handleNext}
      control={control}
      register={register}
      watch={watch}
      errors={errors}
      setFocus={setFocus}
      gameInstalls={gameInstalls}
      minUsersPerGroup={minUsersPerGroup}
      gldParams={gldParams}
      targetConfig={targetConfig}
    />
  );
}
