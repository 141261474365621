import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Routing } from '@infrastructure/routing';
import { experimentListSelectors } from '@infrastructure/store/experimentList/experimentListSelectors';

import { useSelector } from '@ui/hooks/redux';
import { ExpTypeTitleMap } from '@ui/const/ExpTypeTitleMap';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { UniqueId } from '@domain/types';

import { PlatformIcon } from '@components/layout/platformIcon/PlatformIcon';
import { TableCellType } from '@components/layout/table/types/TableCellType';
import { ExperimentStatus } from '@components/layout/experimentStatus/ExperimentStatus';
import { Image } from '@components/layout/Image';
import { ResourceMapper } from '@app/mappers/ResourceMapper';
import { DateLabel } from '@components/layout/dateLabel/DateLabel';
import { CountryLabel } from '@components/layout/countryLabel/CountryLabel';
import { WinnerStatusIcon } from '@pages/winnerConfigModal/atoms/WinnerStatusIcon';

import { ReactComponent as RecSvg } from '@assets/svg/rec.svg';

type Props = {
  experimentId: UniqueId;
};

const getUniqueRegions = (objectives: ExperimentObjectiveDto[]): string[] => {
  const liveRegions = objectives.map((x) => x.regions).flat();
  const countryCodes = liveRegions.map((x) => x.countryCodes[0] || x.name);
  return [...new Set(countryCodes)];
};

export function ExperimentRow({ experimentId }: Props) {
  const navLinkRef = useRef<HTMLAnchorElement>(null);

  const handleNavClick = useCallback((e) => {
    if (navLinkRef.current) {
      navLinkRef.current.click();
    }
  }, []);

  const experiment = useSelector(experimentListSelectors.getExperiment(experimentId));

  if (!experiment) {
    return null;
  }

  const {
    gameApp,
    experimentName,
    experimentType,
    state,
    startDate,
    endDate,
    winnerStatus,
    experimentObjectives,
    isRecommendedProfile
  } = experiment;

  const { gameName, iconUrl, platform } = gameApp;

  const type = ExpTypeTitleMap[experimentType] || 'Unknown';
  const iconUrlFormatted = ResourceMapper.mapS3UrlToCDN(iconUrl);
  const regionList = getUniqueRegions(experimentObjectives);

  return (
    <tr data-id={experimentId} onClick={handleNavClick}>
      <td data-cell={TableCellType.ICON}>
        <Image src={iconUrlFormatted} alt={`${gameName} icon`} />
      </td>
      <td data-cell={TableCellType.TITLE}>{gameName}</td>
      <td data-cell={TableCellType.ICON}>{isRecommendedProfile && <RecSvg />}</td>
      <td data-cell={TableCellType.TITLE}>
        <Link to={Routing.getExperiment(experimentId)} ref={navLinkRef}>
          {experimentName}
        </Link>
      </td>
      <td data-cell={TableCellType.TEXT}>
        <PlatformIcon platform={platform} />
      </td>
      <td data-cell={TableCellType.TEXT}>{type}</td>
      <td data-cell={TableCellType.LABEL}>
        <ExperimentStatus status={state} />
      </td>
      <td data-cell={TableCellType.DATE}>
        <DateLabel date={startDate} />
        <br />
        <DateLabel date={endDate} fallbackText="Present" />
      </td>
      <td data-cell={TableCellType.LABEL}>{regionList.length !== 0 && <CountryLabel countryCode={regionList} />}</td>
      <td data-cell={TableCellType.ICON}>
        {winnerStatus.map((status, index) => (
          <WinnerStatusIcon winnerStatus={status} key={`${status}_${index}`} />
        ))}
      </td>
    </tr>
  );
}
