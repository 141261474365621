import React, { useCallback, useEffect, useState } from 'react';
import { IsNotEmpty } from 'class-validator';
import { UseFormWatch } from 'react-hook-form';
import { Button, ButtonVariant, TextInput } from 'crazy-ui-next';

import { getInputProps, useForm } from '@ui/hooks/form';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';

import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';
import { ReactComponent as AcceptSvg } from '@assets/svg/accept.svg';

import styles from '../ConfigInput.module.scss';

class Param {
  @IsNotEmpty()
  value: string;
}

type Props = {
  handleAddParam: (body: Param) => void;
  watch: UseFormWatch<DevPhaseParams>;
  isDisabled: boolean;
};

export function AddParamBtn({ handleAddParam, watch, isDisabled }: Props) {
  const params = watch('objectiveConfig.params');
  const formattedParams = params.map(({ value }) => value.toLowerCase());
  const [isEdit, setIsEdit] = useState(false);

  const {
    register,
    handleSubmit,
    resetField,
    setFocus,
    setError,
    formState: { errors }
  } = useForm<Param>({ schema: Param });

  const handleEdit = useCallback(() => {
    setIsEdit(true);
  }, [setIsEdit]);

  const handleAdd = handleSubmit((body) => {
    if (formattedParams.includes(body.value.toLowerCase())) {
      setError('value', {
        type: 'custom',
        message: 'Param name should be uniq'
      });
      return;
    }

    handleAddParam(body);
    setIsEdit(false);
    resetField('value');
  });

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleAdd();
      }
      if (event.key === 'Escape') {
        setIsEdit(false);
        resetField('value');
      }
    },
    [setIsEdit, handleAdd, resetField]
  );

  useEffect(() => {
    if (isEdit) {
      setFocus('value');
    }
  }, [isEdit, setFocus]);

  return (
    <div className={styles.addVarWrapper}>
      {isEdit ? (
        <div>
          <TextInput onKeyDown={handleKeyDown} {...getInputProps<Param>('value', register, errors)} />
          <Button variant={ButtonVariant.TERTIARY} onClick={handleAdd} icon={<AcceptSvg />} disabled={isDisabled} />
        </div>
      ) : (
        <div>
          <Button variant={ButtonVariant.TERTIARY} onClick={handleEdit} icon={<PlusSvg />} disabled={isDisabled}>
            Var
          </Button>
        </div>
      )}
    </div>
  );
}
