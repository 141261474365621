import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import { clearAll, pushExperimentInit } from '@infrastructure/store/createExperiment/createExperimentActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { uploadFilesToS3 } from '@infrastructure/store/config/configActions';

import { UniqueHashId } from '@domain/types';
import { pushUpdateDevPhase } from '@infrastructure/store/experimentDetails/experimentDetailsActions';

const TEMP_ID_SIZE = 8;

export type S3ConfigState = {
  tempS3Id: UniqueHashId;
  currentUploadingConfigName: string | null;
};

const initial: S3ConfigState = {
  tempS3Id: nanoid(TEMP_ID_SIZE),
  currentUploadingConfigName: null
};

export const S3ConfigReducer = createReducer(initial, (qb) => {
  qb.addCase(pushExperimentInit.success, (state) => {
    return { ...state, tempS3Id: nanoid(TEMP_ID_SIZE) };
  });
  qb.addCase(pushUpdateDevPhase.success, (state) => {
    return { ...state, tempS3Id: nanoid(TEMP_ID_SIZE) };
  });
  qb.addCase(uploadFilesToS3.trigger, (state, { payload }) => {
    return { ...state, currentUploadingConfigName: payload.configName };
  });
  qb.addCase(uploadFilesToS3.success, (state, { payload }) => {
    return { ...state, currentUploadingConfigName: initial.currentUploadingConfigName };
  });
  qb.addCase(uploadFilesToS3.failure, (state, { payload }) => {
    return { ...state, currentUploadingConfigName: initial.currentUploadingConfigName };
  });
  qb.addCase(clearAll, () => {
    return initial;
  });
});

export const fileUploadReducer = combineReducers({
  status: ReducerFactory.makeStatus(uploadFilesToS3, clearAll),
  config: S3ConfigReducer
});
