import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';
import { UniqueId } from '@domain/types';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';
import { DateConfigParams } from '@domain/models/createExperiment/DateConfigParams';
import { BasicInfoParams } from '@domain/models/createExperiment/BasicInfoParams';
import { RegionDto } from '@domain/models/RegionDto';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { GameStatsType } from '@domain/enums/GameStatsType';
import { AdvancedConfigParams } from '@domain/models/createExperiment/AdvancedConfigParams';
import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { ControlGroupByCountry } from '@domain/models/ControlGroup';
import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { ExperimentFormState } from '@infrastructure/store/createExperiment/createExperimentReducer';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { OverlappedConfigDto } from '@domain/models/experiment/OverlappedConfigDto';
import { ConfigValue } from '@domain/models/GenericConfigValue';
import { GLDParamDto } from '@domain/enums/GLDParamDto';
import { ILiveConfig } from '@domain/models/experiment/LiveConfig';

const namespace = 'createExperiment';

type PushValidateObjectiveTType = { region: string; form: ObjectiveConfigParams };
type PushValidateObjectiveSType = { region: string; configList: GenericConfigEntry[] };
type PushObjectiveType = { region: string; form: ObjectiveConfigParams };

// general actions
export const pushExperimentInit = ActionFactory.createRequestRoutine<void, ExperimentFormState | null>(
  'EXPERIMENT_INIT_PUSH',
  namespace
);
export const pushExperimentCreate = ActionFactory.createRequestRoutine('EXPERIMENT_CREATE_PUSH', namespace);

// form management
export const pushNextStep = ActionFactory.createAction('NEXT_STEP_PUSH', namespace);
export const pushSetStep = ActionFactory.createAction<UniqueId>('SET_STEP_PUSH', namespace);

// basic info
export const pushBasicInfoForm = ActionFactory.createAction<BasicInfoParams>('BASIC_INFO_FORM_PUSH', namespace);
export const setGame = ActionFactory.createAction('SET_GAME', namespace);

// target config
export const fetchRegions = ActionFactory.createRequestRoutine<
  void,
  {
    defaultRegions: RegionDto[];
    recommendedRegions: RegionDto[];
  }
>('REGIONS_FETCH', namespace);
export const fetchGameStats = ActionFactory.createRequestRoutine<void, GameStatsDto[]>('GAME_STATS_FETCH', namespace);
export const setGameStatsFilter = ActionFactory.createAction<GameStatsType>('GAME_STATS_FILTER_SET', namespace);
export const fetchGameInstallsStats = ActionFactory.createRequestRoutine<
  RegionDto,
  { region: string; stats: GameInstallsStatsDto }
>('GAME_INSTALLS_STATS_FETCH', namespace);
export const initTargetConfig = ActionFactory.createRequestRoutine('TARGET_CONFIG_INIT_PUSH', namespace);
export const generateTargetConfig = ActionFactory.createRequestRoutine<
  void,
  { form: TargetConfigParams; isRecommended: boolean }
>('TARGET_CONFIG_GENERATE_PUSH', namespace);
export const pushTargetConfigForm = ActionFactory.createAction<TargetConfigParams>(
  'TARGET_CONFIG_FORM_PUSH',
  namespace
);

// objective configs
export const initABObjectiveConfig = ActionFactory.createAction('AB_OBJECTIVE_CONFIG_PUSH', namespace);
export const initGLDObjectiveConfig = ActionFactory.createAction('GLD_OBJECTIVE_CONFIG_PUSH', namespace);
export const generateABObjectiveConfig = ActionFactory.createRequestRoutine<
  void,
  { configList: Record<string, GenericConfigEntry[]>; form: Record<string, ObjectiveConfigParams> }
>('AB_OBJECTIVE_CONFIG_GENERATE_PUSH', namespace);
export const fetchObjectiveConfig = ActionFactory.createRequestRoutine<void, ControlGroupByCountry>(
  'OBJECTIVE_CONFIG_FETCH',
  namespace
);
export const pushABObjectiveForm = ActionFactory.createAction<PushObjectiveType>('AB_OBJECTIVE_FORM_PUSH', namespace);
export const pushGLDObjectiveForm = ActionFactory.createAction<GLDConfigParams>('GLD_OBJECTIVE_FORM_PUSH', namespace);
export const pushValidateABObjectiveForm = ActionFactory.createRequestRoutine<
  PushValidateObjectiveTType,
  PushValidateObjectiveSType
>('VALIDATE_AB_OBJECTIVE_FORM_PUSH', namespace);
export const pushValidateGLDObjectiveForm = ActionFactory.createRequestRoutine(
  'VALIDATE_GLD_OBJECTIVE_FORM_PUSH',
  namespace
);
export const removeConfig = ActionFactory.createAction<string>('AB_OBJECTIVE_CONFIG_REMOVE', namespace);
export const removeGroupFromConfig = ActionFactory.createAction<{ index: number; region: string }>(
  'AB_OBJECTIVE_CONFIG_REMOVE_GROUP',
  namespace
);

export const validateOverlapping = ActionFactory.createRequestRoutine<void, OverlappedConfigDto[]>(
  'VALIDATE_OVERLAPPING_EXPERIMENTS',
  namespace
);
export const fetchLiveConfigs = ActionFactory.createRequestRoutine<void, ILiveConfig[]>(
  'LIVE_CONFIGS_FETCH',
  namespace
);
export const updateConfigEntry = ActionFactory.createAction<{
  configValue: ConfigValue | Record<string, ConfigValue>;
  region: string;
  configIndex: number;
  variableKey: string;
}>('OBJECTIVE_VALIDATED_CONFIG_UPDATE', namespace);
export const submitObjectivesStep = ActionFactory.createRequestRoutine('OBJECTIVES_STEP_SUBMIT', namespace);
export const fetchGLDParams = ActionFactory.createRequestRoutine<UniqueId, GLDParamDto[] | null>(
  'GLD_PARAMS_FETCH',
  namespace
);

// goal config
export const initGoalConfig = ActionFactory.createAction('GOAL_CONFIG_INIT_PUSH', namespace);
export const generateGoalConfig = ActionFactory.createRequestRoutine<
  void,
  { form: GoalConfigParams; isRecommended: boolean }
>('GOAL_CONFIG_GENERATE_PUSH', namespace);
export const pushGoalConfigForm = ActionFactory.createAction<GoalConfigParams>('GOAL_CONFIG_FORM_PUSH', namespace);

// date config
export const initDatesConfig = ActionFactory.createAction('DATES_CONFIG_INIT_PUSH', namespace);
export const generateDatesConfig = ActionFactory.createRequestRoutine<
  void,
  { form: DateConfigParams; isRecommended: boolean }
>('DATES_CONFIG_GENERATE_PUSH', namespace);
export const pushDatesConfigForm = ActionFactory.createAction<DateConfigParams>('DATES_CONFIG_FORM_PUSH', namespace);

// advanced config
export const pushAdvancedConfigForm = ActionFactory.createAction<AdvancedConfigParams>(
  'ADVANCED_CONFIG_FORM_PUSH',
  namespace
);

export const clearAll = ActionFactory.createAction('CLEAR_ALL', namespace);
