import React from 'react';

import { ReactComponent as DotsIcon } from '@assets/svg/dots.svg';

import styles from './CountryLabel.module.scss';
import { CountryCircle } from '@components/layout/countryLabel/CountryCircle';

type Props = {
  countryCode: string | string[];
  visibleCount?: number;
};

export function CountryLabel({ countryCode, visibleCount = 4 }: Props) {
  if (Array.isArray(countryCode)) {
    if (!countryCode.length) {
      return null;
    }

    const visibleList = countryCode.slice(0, visibleCount);
    const hiddenCount = countryCode.length - visibleList.length;

    return (
      <div className={styles.wrapper} data-count={visibleList.length}>
        {visibleList.map((code) => (
          <CountryCircle key={code} countryCode={code} />
        ))}
        {hiddenCount > 0 && (
          <div className={styles.circle}>
            <DotsIcon />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <CountryCircle countryCode={countryCode} />
    </div>
  );
}
