import React from 'react';
import { useSelector } from 'react-redux';
import { memoize } from 'proxy-memoize';

import { configSelectors } from '@infrastructure/store/config/configSelectors';

type Props = {
  variables: string[];
};

export function THead({ variables }: Props) {
  const variableTitle = useSelector(memoize(configSelectors.getVariableTitleMap));

  return (
    <thead>
      <tr>
        {variables.map((key) => (
          <th key={key}>{variableTitle[key] || key}</th>
        ))}
      </tr>
    </thead>
  );
}
