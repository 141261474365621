import React from 'react';

import { NotificationType } from '@pages/createExperiment/notifications/NotificationType';

import { ReactComponent as WarningSvg } from '@assets/svg/warning.svg';
import { ReactComponent as InfoSvg } from '@assets/svg/info-rounded.svg';

import styles from '@components/layout/overlapedNotifications/Notification.module.scss';

function getNotificationTypeIcon(type: NotificationType) {
  switch (type) {
    case NotificationType.INFO:
      return <InfoSvg />;
    default:
    case NotificationType.WARNING:
      return <WarningSvg />;
  }
}

type Props = {
  title: string;
  children: React.ReactNode;
  type?: NotificationType;
};

export function Notification({ title, children, type = NotificationType.WARNING }: Props) {
  return (
    <div className={styles.notification} data-notification-type={type}>
      <div className={styles.header}>
        {getNotificationTypeIcon(type)}
        <h4>{title}</h4>
      </div>
      {children}
    </div>
  );
}
