import { Dto } from '@domain/models/Dto';
import { GenericConfigValue } from '@domain/models/GenericConfigValue';

export interface ZipFileInfo {
  md5checksum: string;
  url: string;
}

export class GenericConfigEntry extends Dto {
  public entry: Record<string, GenericConfigValue>;
  public name: string;
  public description: string | null;
  public active: boolean;
  public files: string[] | null;
  public zipFileInfo: ZipFileInfo | null;
}
