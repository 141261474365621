import { uniq } from 'lodash-es';
import { DropdownOption } from 'crazy-ui-next';

import { AppState } from '@infrastructure/store';
import { createSimpleOptions } from '@infrastructure/utils/createOptions.utils';
import { configSelectors } from '@infrastructure/store/config/configSelectors';

import { GamePlatform, GamePlatformTitleMap } from '@domain/enums/GamePlatform';
import { CreateDirectConfigForm } from '@domain/enums/CreateDirectConfigForm';
import { RegionDto } from '@domain/models/RegionDto';

const getStep = (state: AppState) => state.createDirectConfig.formStep.step;
const getFinalStep = (state: AppState) => state.createDirectConfig.formStep.finalStep;

const getIsFormReady = (state: AppState) => {
  const step = getStep(state);
  const finalStep = getFinalStep(state);

  return step > finalStep;
};

const getStatus = (state: AppState) => state.createDirectConfig.status;

const getForm = (state: AppState) => state.createDirectConfig.form;

const getGameNameOptions = (state) => {
  const games = configSelectors.getGameApplicationList(state);
  const gameNames = games.map((game) => game.gameName);
  const onlyUniqueNames = uniq(gameNames);

  const sortedNames = onlyUniqueNames.sort((a, b) => a.localeCompare(b));

  return createSimpleOptions(sortedNames);
};

const getGamesByNameAndPlatform = (gameName: string, platform: GamePlatform, state: AppState) => {
  const games = configSelectors.getGameApplicationList(state);
  return games.filter((game) => game.gameName === gameName && game.platform === platform);
};

const getPlatformOptions = (gameName: string) => (state: AppState) => {
  const options: DropdownOption<GamePlatform>[] = [
    { value: GamePlatform.IOS, label: GamePlatformTitleMap[GamePlatform.IOS] },
    { value: GamePlatform.ANDROID, label: GamePlatformTitleMap[GamePlatform.ANDROID] }
  ];
  const games = configSelectors.getGameApplicationList(state);
  const filteredGames = games.filter((game) => game.gameName === gameName);
  const platforms = filteredGames.map(({ platform }) => platform);

  return options.filter(({ value }) => platforms.includes(value));
};

const getBundleIdOptions = (gameName: string, platform: GamePlatform) => (state: AppState) => {
  const games = getGamesByNameAndPlatform(gameName, platform, state);
  const bundleIds = games.map(({ bundleId }) => bundleId);
  const onlyUniqueIds = uniq(bundleIds);

  return createSimpleOptions(onlyUniqueIds);
};

const getFirebaseOptions = (gameName: string, platform: GamePlatform, bundleId: string) => (state: AppState) => {
  const games = getGamesByNameAndPlatform(gameName, platform, state);
  const filteredGamesByBundleId = games.filter((game) => game.bundleId === bundleId);

  return filteredGamesByBundleId.map((game) => ({
    label: game.firebaseProjectName,
    value: game.firebaseProjectId
  }));
};

const getRegions = (state: AppState) => state.createDirectConfig.regions;

const getRegionByName =
  (region: string) =>
  (state: AppState): RegionDto | null => {
    const { defaultRegions, recommendedRegions } = getRegions(state);
    const allRegions = [...defaultRegions, ...recommendedRegions];

    return allRegions.find(({ name }) => region === name) || null;
  };

const getGameStats = (state: AppState) => state.createDirectConfig.gameStats.data;

const getGameStatsByPlatform = (state: AppState) => {
  const stats = getGameStats(state);
  const { platform } = getForm(state)[CreateDirectConfigForm.BASIC_INFO];

  return stats.filter((stat) => stat.platform === platform);
};
const getGameStatsFilter = (state: AppState) => state.createDirectConfig.gameStats.filter;
const getControlGroups = (state: AppState) => state.createDirectConfig.controlGroups.data;
const getControlGroupsStatus = (state: AppState) => state.createDirectConfig.controlGroups.status;

const isClone = (state: AppState) => state.createDirectConfig.clone.isClone;

const cloneStatus = (state: AppState) => {
  return state.createDirectConfig.clone.status;
};

const getGameConfigParams = (state: AppState) => state.createDirectConfig.params.gameConfig.data;

const getOverlappedConfigs = (state: AppState) => state.createExperiment.overlappedConfigs;
const getLiveConfigs = (state: AppState) => state.createDirectConfig.liveConfigs;
const getConfigList = (state: AppState) => state.createDirectConfig.configList;

export const createDirectConfigSelectors = {
  getStatus,
  getStep,
  getFinalStep,
  getIsFormReady,

  getForm,

  getRegions,
  getRegionByName,
  getGameStats,
  getGameStatsByPlatform,
  getGameStatsFilter,
  getControlGroups,

  getPlatformOptions,
  getBundleIdOptions,
  getGameNameOptions,
  getFirebaseOptions,
  getControlGroupsStatus,
  cloneStatus,
  isClone,
  getGameConfigParams,
  getConfigList,
  getOverlappedConfigs,
  getLiveConfigs
};
