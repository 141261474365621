import React from 'react';

import { Link } from '@domain/types';

import styles from './SideMenu.module.scss';

type Props = {
  text: string;
  icon: JSX.Element;
  href: Link;
};

export function SideMenuExternalLink({ text, icon, href }: Props) {
  return (
    <a href={href} className={styles.item} target="_blank" rel="noopener noreferrer">
      <div className={styles.icon}>{icon}</div>
      <p>{text}</p>
    </a>
  );
}
