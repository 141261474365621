import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushExperimentInit,
  pushGLDObjectiveForm
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';

const objectiveInitial: GLDConfigParams = GLDConfigParams.ofInitial();

export const objectiveGLDConfigReducer = createReducer(objectiveInitial, (qb) => {
  qb.addCase(pushGLDObjectiveForm, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushExperimentInit.success, (state, { payload }) => {
    if (payload) {
      return payload.objectiveGLDConfig;
    }

    return state;
  });
  qb.addCase(clearAll, () => {
    return GLDConfigParams.ofInitial();
  });
});
