import React from 'react';

import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';

import { TCell } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/adProfileSummary/atoms/TCell';
import { GroupNameCell } from '@pages/createExperiment/objectiveConfig/forms/AB/components/ABFormSummary/atoms/groupNameCell/GroupNameCell';

type Props = { config: GenericConfigEntry; region: string; index: number };

export function TRow({ config, index, region }: Props) {
  return (
    <tr>
      <GroupNameCell groupName={config.name} index={index} region={region} />
      {Object.keys(config.entry).map((key) => {
        return <TCell key={`cell_${index}_${key}`} entry={config.entry[key]} />;
      })}
    </tr>
  );
}
