import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushBasicInfoForm,
  fetchGameStats,
  pushExperimentInit,
  pushSetStep,
  generateABObjectiveConfig,
  generateTargetConfig
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { BasicInfoParams } from '@domain/models/createExperiment/BasicInfoParams';

const basicInfoInitial: BasicInfoParams = BasicInfoParams.ofInitial();

export const basicInfoReducer = createReducer(basicInfoInitial, (qb) => {
  qb.addCase(pushBasicInfoForm, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushExperimentInit.success, (state, { payload }) => {
    if (payload) {
      return payload.basicInfo;
    }
    return state;
  });
  qb.addCase(fetchGameStats.failure, (state) => {
    state.isRecommendedProfile = false;
    return state;
  });
  qb.addCase(generateTargetConfig.success, (state, { payload }) => {
    if (!payload.isRecommended) {
      state.isRecommendedProfile = false;
    }

    return state;
  });
  qb.addCase(generateTargetConfig.failure, (state) => {
    state.isRecommendedProfile = false;
    return state;
  });
  qb.addCase(generateABObjectiveConfig.failure, (state) => {
    state.isRecommendedProfile = false;
    return state;
  });
  qb.addCase(pushSetStep, (state) => {
    state.isRecommendedProfile = false;
    return state;
  });
  qb.addCase(clearAll, () => {
    return BasicInfoParams.ofInitial();
  });
});
