import React from 'react';
import { Control, UseFormRegister, UseFormResetField, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import cn from 'classnames';

import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';
import { UserProperty } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';
import { LiveRegionsInput } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/inputs/LiveRegionsInput';
import { PrimaryRegionInput } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/inputs/PrimaryRegionInput';
import { InDevRegionsInput } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/inputs/InDevRegionsInput';
import { VersionsInput } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/inputs/VersionsInput';
import { GameStatsContainer } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/GameStatsContainer';
import { UserPropertiesContainer } from '@pages/createExperiment/targetConfig/userProperties/UserPropertiesContainer';

import styles from '../../DevPhase.module.scss';

type Props = {
  register: UseFormRegister<DevPhaseParams>;
  setValue: UseFormSetValue<DevPhaseParams>;
  watch: UseFormWatch<DevPhaseParams>;
  control: Control<DevPhaseParams>;
  resetField: UseFormResetField<DevPhaseParams>;
  userProperties: UserProperty[];
  operators: UserPropertyOperator[];
  isDisabled: boolean;
};

export function TargetingConfigSection({
  watch,
  control,
  register,
  resetField,
  setValue,
  userProperties,
  operators,
  isDisabled
}: Props) {
  return (
    <div>
      <div className={styles.gameStats}>
        <GameStatsContainer />
      </div>
      <ul className={cn(styles.formSection, styles.devPhase)}>
        <PrimaryRegionInput control={control} watch={watch} resetField={resetField} isDisabled={isDisabled} />
        <LiveRegionsInput control={control} resetField={resetField} isDisabled={isDisabled} />
        <InDevRegionsInput control={control} resetField={resetField} isDisabled={isDisabled} />
        <VersionsInput register={register} control={control} isDisabled={isDisabled} />

        <li>
          <div className={styles.userPropertiesLabel}>
            <p>User Properties:</p>
          </div>
          <div>
            <UserPropertiesContainer<DevPhaseParams>
              watch={watch}
              control={control}
              setValue={setValue}
              userProperties={userProperties}
              operators={operators}
              watchName="targetingConfig.userProperties.inputs"
              hideLabel
              isDisabled={isDisabled}
            />
          </div>
        </li>
      </ul>
    </div>
  );
}
