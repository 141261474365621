import { Service } from 'typedi';

import { FileManagerApi } from '@infrastructure/api/FileManagerApi';

import { MessageResponseDto } from '@domain/models/MessageResponseDto';
import { UniqueHashId } from '@domain/types';
import { FileManagerUseCase } from '@domain/useCases/FileManagerUseCase';

@Service()
export class FileManagerService implements FileManagerUseCase {
  constructor(private readonly fileManagerApi: FileManagerApi) {}

  async getSignedS3Url(file: File, abGroup: string, tempId: UniqueHashId): Promise<string> {
    return this.fileManagerApi.getSignedS3Url(file.name, abGroup, tempId);
  }

  async uploadFileToAWSBucket(file: File, uploadUrl: string): Promise<MessageResponseDto> {
    return this.fileManagerApi.uploadFileToAWSBucket(file, uploadUrl);
  }
}
