import { Service } from 'typedi';

import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { ExperimentApi } from '@infrastructure/api/ExperimentApi';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { RegionService } from '@app/services/RegionService';
import { GameService } from '@app/services/GameService';
import { ConfigService } from '@app/services/ConfigService';
import { ControlGroupByCountry } from '@domain/models/ControlGroup';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { RegionDto } from '@domain/models/RegionDto';
import { Routing } from '@infrastructure/routing';
import { HistoryService } from '@infrastructure/browser/HistoryService';
import { TargetConfigParams } from '@pages/createDirectConfig/targetConfig/types/TargetConfigParams';
import { ExperimentRegionName } from '@domain/enums/ExperimentRegion';
import { GLDParamDto } from '@domain/enums/GLDParamDto';
import { UserPropertiesDto } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { CreateDirectConfigForm } from '@domain/enums/CreateDirectConfigForm';
import { DirectConfigType } from '@domain/enums/directConfig/DirectConfigType';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { DirectConfigFormMapper } from '@app/mappers/directConfig/DirectConfigFormMapper';
import { OverlappedConfigDto } from '@domain/models/experiment/OverlappedConfigDto';
import { ILiveConfig } from '@domain/models/experiment/LiveConfig';
import { DirectConfigFormState } from '@infrastructure/store/createDirectConfig/createDirectConfigReducer';
import { DirectConfigService } from '@app/services/DirectConfigService';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { AdProfileFormMapper } from '@app/mappers/directConfig/AdProfileFormMapper';
import { GameConfigFormMapper } from '@app/mappers/directConfig/GameConfigFormMapper';

@Service()
export class CreateDirectConfigService {
  constructor(
    private readonly reduxRepo: ReduxRepo,
    private readonly directConfigService: DirectConfigService,
    private readonly experimentApi: ExperimentApi,
    private readonly regionService: RegionService,
    private readonly gameService: GameService,
    private readonly configService: ConfigService,
    private readonly historyService: HistoryService
  ) {}

  async fetchRegions() {
    const form = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);
    const { gameId } = form[CreateDirectConfigForm.BASIC_INFO];

    const [defaultRegions, recommendedRegions] = await Promise.all([
      this.regionService.getDefaultRegions(),
      this.regionService.getRecommendedRegions(gameId)
    ]);

    return { defaultRegions, recommendedRegions };
  }

  async fetchGameStats(): Promise<GameStatsDto[]> {
    const { basicInfo } = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);

    return this.gameService.fetchGameStats(basicInfo.gameId);
  }

  async fetchGameInstallsStats(regionData: RegionDto): Promise<{ region: string; stats: GameInstallsStatsDto }> {
    const { basicInfo } = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);

    const stats = await this.gameService.fetchInstallsByRegion(basicInfo.gameId, regionData.id);

    return { region: regionData.name, stats };
  }

  async fetchUserProperties(): Promise<UserPropertiesDto> {
    const { basicInfo } = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);

    return this.configService.getUserProperties(basicInfo.firebaseProjectId);
  }

  async fetchControlGroups(): Promise<ControlGroupByCountry> {
    const { basicInfo } = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);

    return this.configService.getControlGroups(basicInfo.gameId);
  }

  async create(): Promise<IDirectConfig> {
    const form = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);
    const { defaultRegions } = this.reduxRepo.findBy(createDirectConfigSelectors.getRegions);
    const configList = this.reduxRepo.findBy(createDirectConfigSelectors.getConfigList);

    const payload = DirectConfigFormMapper.formToCreatePayload(form, defaultRegions, configList);

    return this.directConfigService.create(payload);
  }

  generateTargetConfig(): TargetConfigParams {
    const { basicInfo, targetConfig } = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);
    const isCLone = this.reduxRepo.findBy(createDirectConfigSelectors.isClone);

    if (isCLone) {
      return targetConfig;
    }

    const { type, gameId } = basicInfo;

    const game = this.reduxRepo.findBy(configSelectors.getGameById(gameId));

    const isGameConfig = type === DirectConfigType.GAME_CONFIG;

    const form = new TargetConfigParams();

    if (game?.storeVersion) {
      form.setVersion(game.storeVersion);
    }

    if (isGameConfig) {
      const WWRegionOption = { value: ExperimentRegionName.WW, label: ExperimentRegionName.WW };

      form.pushRegions(WWRegionOption);

      return form;
    }

    return form;
  }

  navigateToMainPage() {
    this.historyService.goTo(Routing.getDirectConfigList());
  }

  getGameConfigParams(): Promise<GLDParamDto[] | null> {
    const { basicInfo } = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);

    return this.configService.getGLDParams(basicInfo.gameId);
  }

  async validateOverlappingExperiments(): Promise<OverlappedConfigDto[]> {
    const form = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);

    const { gameId, type } = form.basicInfo;
    const { regions: regionOptions, versions } = form.targetConfig;
    const regions = regionOptions.map((option) => option.value);

    const appVersions = versions.map(({ value }) => value);

    return this.experimentApi.validateOverlappingExperiment(gameId, regions, appVersions, type);
  }

  async fetchLiveConfigs(): Promise<ILiveConfig[]> {
    const form = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);

    const { gameId, type } = form.basicInfo;

    return this.gameService.fetchLiveConfigs(gameId, type);
  }

  async calculateAdProfileGenericConfig(): Promise<GenericConfigEntry> {
    const form = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);
    const variables = this.reduxRepo.findBy(configSelectors.getVariableList);

    const { adProfileObjective } = form;

    return AdProfileFormMapper.toGenericConfig(adProfileObjective.params, adProfileObjective.controlGroup, variables);
  }

  async calculateGameConfigGenericConfig(): Promise<GenericConfigEntry> {
    const form = this.reduxRepo.findBy(createDirectConfigSelectors.getForm);

    const { gameConfigObjective } = form;

    return GameConfigFormMapper.toGenericConfig(gameConfigObjective.config);
  }

  async clone(): Promise<DirectConfigFormState | null> {
    const { clone: directConfigId } = this.historyService.getSearchParams();
    const variables = this.reduxRepo.findBy(configSelectors.getVariableList);

    if (!directConfigId) {
      return null;
    }

    const directConfig = await this.directConfigService.get(Number(directConfigId));

    return DirectConfigFormMapper.directConfigToForm(directConfig, variables);
  }
}
