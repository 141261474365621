import React from 'react';

import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';

import { ExperimentConfigContainer } from '@pages/experimentDetails/components/summary/config/ExperimentConfigContainer';
import { Info } from '@pages/experimentDetails/components/summary/Info';

import { ExperimentState } from '@domain/enums/ExperimentState';

import styles from './Summary.module.scss';

type Props = {
  experiment: ExperimentDto;
  isActive: boolean;
  handleToggle: () => void;
};

export function Summary({ experiment, isActive, handleToggle }: Props) {
  const { state } = experiment;

  const isExpandable = ![ExperimentState.IN_DEV, ExperimentState.SCHEDULED_IN_DEV].includes(state);
  const showConfigContainer = (isExpandable && isActive) || !isExpandable;

  return (
    <section className={styles.wrapper}>
      <Info
        experiment={experiment}
        isActive={isActive}
        isExpandableSummary={isExpandable}
        handleToggle={handleToggle}
      />
      {showConfigContainer && <ExperimentConfigContainer />}
    </section>
  );
}
