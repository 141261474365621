import React from 'react';
import { TRow } from './TRow';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';

type Props = {
  variables: string[];
  config: GenericConfigEntry;
};

export function TBody({ variables, config }: Props) {
  return (
    <tbody>
      <TRow config={config} variables={variables} />
    </tbody>
  );
}
