import React from 'react';
import { Control, FieldArrayWithId, UseFormSetFocus } from 'react-hook-form';

import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { ConfigName } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/ConfigName';
import { EditableDescription } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/EditableDescription';
import { EditableCell } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/cell/EditableCell';
import { FileUploadCellContainer } from '@components/forms/fileUploadCell/FileUploadCellContainer';

type Props = {
  control: Control<GLDConfigParams>;
  setFocus: UseFormSetFocus<GLDConfigParams>;
  configs: FieldArrayWithId<GLDConfigParams, 'config'>[];
  handleRemoveConfig: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function TBody({ configs, handleRemoveConfig, control, setFocus }: Props) {
  return (
    <tbody>
      {configs.map((config, configIndex) => (
        <tr key={config.id}>
          <ConfigName name={config.name} configIndex={configIndex} handleRemoveConfig={handleRemoveConfig} />
          <EditableDescription control={control} configIndex={configIndex} setFocus={setFocus} />
          {config.input.map((input, inputIndex) => {
            const isFile = input.key === 'files';

            if (isFile) {
              return (
                <FileUploadCellContainer<GLDConfigParams>
                  key={input.key}
                  fieldName={`config.${configIndex}.input.${inputIndex}.value`}
                  control={control}
                  configName={config.name}
                />
              );
            }

            return <EditableCell key={input.key} control={control} configIndex={configIndex} inputIndex={inputIndex} />;
          })}
        </tr>
      ))}
    </tbody>
  );
}
