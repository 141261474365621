import React from 'react';
import cn from 'classnames';

import { Button, ButtonVariant } from 'crazy-ui-next';
import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';
import { ReactComponent as MinusSvg } from '@assets/svg/minus.svg';

import styles from './MultiTextInput.module.scss';

type Props = {
  inputs: React.ReactNode[];
  onChange?: () => void;
  onAddItem?: () => void;
  onRemoveItem?: () => void;
  className?: string;
  maxItems: number;
  minItems?: number;
  isValid?: boolean;
  isDisabled?: boolean;
};

// todo move to global reusable lvl
export function MultiTextInputContainer({
  inputs,
  onAddItem,
  onRemoveItem,
  className,
  maxItems,
  minItems = 1,
  isValid = true,
  isDisabled = false
}: Props) {
  const showAdd = inputs.length < maxItems && !isDisabled;
  const showRemove = inputs.length > minItems && maxItems !== minItems && !isDisabled;

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.inputs, !isValid && styles.errorInputs)}>{inputs}</div>

      <div className={styles.controls}>
        {showRemove && (
          <Button
            className={styles.minus}
            variant={ButtonVariant.TERTIARY}
            onClick={onRemoveItem}
            icon={<MinusSvg />}
          />
        )}
        {showAdd && <Button variant={ButtonVariant.TERTIARY} onClick={onAddItem} icon={<PlusSvg />} />}
      </div>
    </div>
  );
}
