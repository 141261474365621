import { AppState } from '@infrastructure/store';
import { BundleId, UniqueId } from '@domain/types';
import { ExpVariableMapper } from '@app/mappers/ExpVariableMapper';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';

const getConfigStatus = (state: AppState) => state.config.status;
const getEditConfigStatus = (state: AppState) => state.config.editStatus;
const getVersion = (state: AppState) => state.config.version;
const getPeriodicityConfig = (state: AppState) => state.config.periodicity;
const getExperimentTypesConfig = (state: AppState) => state.config.experimentTypes;

const getPlatformsConfig = (state: AppState) => state.config.platforms;
const getQueryPlaceholdersConfig = (state: AppState) => state.config.queryPlaceholders;
const getKpiConfig = (state: AppState) => state.config.kpi;
const getMinUsersPerGroup = (state: AppState) => state.config.minUsersPerGroup;

const getGames = (state: AppState): GameApplicationDto[] => state.config.games;

const getGameById =
  (gameId: UniqueId) =>
  (state: AppState): GameApplicationDto | null => {
    const games = getGames(state);
    return games.find(({ id }) => id === gameId) || null;
  };

/** @deprecated Should be removed from settings */
const getGameApplicationList = (state: AppState) => state.config.games;

/** @deprecated Should be removed from settings */
const getGameApplicationById = (state: AppState, gameId: number) =>
  getGameApplicationList(state).find(({ id }) => gameId === id);

const getGameAppByPartialData =
  (firebaseProjectId: string, platform: GamePlatform, bundleId: BundleId) => (state: AppState) => {
    const games = getGameApplicationList(state);
    return games.find(
      (game) => game.firebaseProjectId === firebaseProjectId && game.platform === platform && bundleId === game.bundleId
    );
  };

/* Variables */
const getVariableList = (state: AppState) => state.config.variables;

const getVariableMap = (state: AppState) => {
  const list = getVariableList(state);
  return Object.fromEntries(list.map((x) => [x.name, x]));
};

const getVariableById = (id: UniqueId) => (state: AppState) => getVariableList(state).find((x) => x.id === id) || null;
const getEditVariableById = (id: UniqueId) => (state: AppState) => {
  const dto = getVariableById(id)(state);
  return ExpVariableMapper.mapDtoToUpdateParams(dto);
};

const getVariableTitleMap = (state: AppState) => {
  const list = getVariableList(state);
  return Object.fromEntries(list.map((x) => [x.name, x.displayName]));
};

const getVariableTypeMap = (state: AppState) => {
  const list = getVariableList(state);
  return Object.fromEntries(list.map((x) => [x.name, x.type]));
};

const isVarHasSessionSupport = (variableName: string) => (state: AppState) => {
  const list = getVariableList(state);
  const variable = list.find((x) => x.name === variableName);
  return variable?.sessionsSupported;
};

const getPlainVariablesKeys = (state: AppState) => {
  const list = getVariableList(state);
  return list.filter((x) => !x.sessionsSupported);
};

const getUserProperties = (state: AppState) => state.config.userProperties;
const getUserPropertyOperators = (state: AppState) => state.config.userPropertyOperators;

const getS3FileUploadStatus = (state: AppState) => state.config.fileUpload.status;
const getS3Config = (state: AppState) => state.config.fileUpload.config;

export const configSelectors = {
  getConfigStatus,
  getEditConfigStatus,
  getVersion,
  getPeriodicityConfig,
  getExperimentTypesConfig,
  getPlatformsConfig,
  getQueryPlaceholdersConfig,
  getKpiConfig,
  getGameById,
  getGameApplicationList,
  getGameApplicationById,
  getVariableList,
  getVariableMap,
  getVariableById,
  getEditVariableById,
  isVarHasSessionSupport,
  getPlainVariablesKeys,
  getVariableTitleMap,
  getVariableTypeMap,
  getMinUsersPerGroup,
  getGameAppByPartialData,
  getUserProperties,
  getUserPropertyOperators,
  getS3FileUploadStatus,
  getS3Config
};
