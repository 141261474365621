import React from 'react';
import { Button, ButtonVariant, ModalWrapper } from 'crazy-ui-next';

import { ILiveConfig } from '@domain/models/experiment/LiveConfig';
import { List } from '@components/layout/overlapedNotifications/liveConfigurations/list/List';
import { Notification } from '@components/layout/overlapedNotifications/Notification';

import styles from '@components/layout/confirmLiveConfigsModal/ConfigLiveConfigsModal.module.scss';

type Props = {
  handleCancel: () => void;
  handleSubmit: () => void;
  liveConfigs: ILiveConfig[];
};

export function ConfirmLiveConfigsModal({ handleCancel, handleSubmit, liveConfigs }: Props) {
  return (
    <ModalWrapper handleModal={handleCancel} className={styles.wrapper}>
      <div className={styles.header}>
        <p>Live configurations</p>
      </div>

      <p>
        The following configurations are live for this game. Please review them before starting the new configuration.
      </p>

      <div className={styles.configs}>
        <Notification title="Live configurations">
          <List configs={liveConfigs} />
        </Notification>
      </div>

      <div className={styles.controls}>
        <Button variant={ButtonVariant.SECONDARY} onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Start</Button>
      </div>
    </ModalWrapper>
  );
}
