import React, { useEffect } from 'react';
import { Control, useController, UseFormResetField, UseFormWatch } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';
import cn from 'classnames';

import { createSimpleOptions } from '@infrastructure/utils/createOptions.utils';

import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';

import styles from '../TargetingConfig.module.scss';

const inputName = 'targetingConfig.primaryRegion';
const watchName = 'targetingConfig.regions';

type Props = {
  control: Control<DevPhaseParams>;
  watch: UseFormWatch<DevPhaseParams>;
  resetField: UseFormResetField<DevPhaseParams>;
  isDisabled: boolean;
};

export function PrimaryRegionInput({ control, watch, resetField, isDisabled }: Props) {
  const regions = watch(watchName);
  const { field, formState } = useController({ control, name: inputName });

  const options = createSimpleOptions(regions.map(({ value }) => value));

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== watchName && type !== 'change') {
        return;
      }

      const { targetingConfig } = value;

      if (targetingConfig?.regions) {
        const regionsNames = targetingConfig?.regions.map((option) => option?.value);

        !regionsNames.includes(targetingConfig?.primaryRegion) && resetField(inputName);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, resetField, regions, field]);

  const error = formState.errors?.targetingConfig?.primaryRegion?.message;

  return (
    <li>
      <div className={styles.editorInputLabel}>
        <p>Decisioning region:</p>
      </div>
      <DropdownInput
        placeholder="Select region"
        options={options}
        className={cn(styles.input, styles.dropdownInput)}
        value={field.value}
        handleChange={(event) => field.onChange(event.value)}
        name={inputName}
        error={error}
        disabled={isDisabled}
      />
    </li>
  );
}
