import { combineReducers, createReducer } from '@reduxjs/toolkit';

import { clearAll, pushExperimentInit } from '@infrastructure/store/createExperiment/createExperimentActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';

const initial = false;

export const isCloneReducer = createReducer(initial, (qb) => {
  qb.addCase(pushExperimentInit.success, (state, { payload }) => {
    return Boolean(payload);
  });
  qb.addCase(clearAll, () => {
    return initial;
  });
});

export const cloneReducer = combineReducers({
  status: ReducerFactory.makeStatus([pushExperimentInit], clearAll),
  isClone: isCloneReducer
});
